/**
 * Created by Administrator on 2020/3/30 0030.
 */
import React from 'react';
import "./../css/shop.css"
import {Layout, Icon, Carousel, Row, Col, Avatar, Menu} from "antd";
import ImageSlider from "./../images/slider_img_1.jpg";
import ImageSlider2 from "./../images/slider_img_2.jpg";
import SearchBar from "../component/layout/SearchBar";
// import NavigationBar from "../component/layout/NavigationBar";
import TopBanner from "../component/layout/TopBanner";
import ShopWindowComponent from "../component/shop/ShopWindowComponent";
// import BackgroundBroadcast from "./../images/background_broadcast.jpg";
import {Link} from "react-router-dom";
import PageFooter from "../component/layout/PageFooter";

const {Header, Content, Footer} = Layout;

export default class ShoppingMallPage extends React.Component {
    constructor(props) {
        super(props);
        const token = localStorage.getItem("token");
        const userName = localStorage.getItem("userName");
        const userUrl = localStorage.getItem("userUrl");
        this.state = {
            userName: token && userName ? userName : null,
            userUrl: token && userUrl ? userUrl : null
        }
    }

    render() {
        const {userName, userUrl} = this.state;
        return <Layout className="layout shop">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                {/*<NavigationBar selectedKey="shop"/>*/}
                <div className="navigation-container">
                    <div className="navigation-wrapper">
                        <div className="categories">
                            <Icon type="menu" style={{fontSize: "20px", marginRight: 10, marginTop: 2}}/>
                            产品分类
                        </div>
                        <Menu
                            className="navigation"
                            theme="dark"
                            mode="horizontal"
                            selectedKeys={["shop"]}
                        >
                            <Menu.Item key="home">
                                <Link to="/">
                                    首页
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="shop">
                                <Link to="/shop">
                                    产品商城
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="news">
                                <Link to="/news">
                                    企业新闻
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="about">
                                <Link to="/about">
                                    关于我们
                                </Link>
                            </Menu.Item>
                        </Menu>
                        <div className="hot-line">
                            <Icon type="phone" theme="filled" style={{fontSize: 18, marginRight: 10}}/>
                            <span><span style={{fontWeight: "700", marginRight: 5}}>客服热线 :</span>400-028-1662</span>
                        </div>
                    </div>
                </div>
            </Header>
            <Content overlay="" className="body">
                <div className="top-banner-container">
                    <div className="top-banner-wrapper">
                        <Row>
                            <Col span={5}>
                                <Menu style={{
                                    height: 361,
                                    borderLeft: "1px solid #eeeeee",
                                    borderBottom: "1px solid #eeeeee",
                                    borderRight: "none"
                                }}>
                                    <Menu.Item
                                        style={{
                                            padding: "0px 20px",
                                            lineHeight: "40px",
                                            color: "#555555",
                                            fontSize: "16px",
                                            fontWeight: 500
                                        }}>
                                        <Link to={"/search/all/anything"}>
                                            全部
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item
                                        style={{
                                            padding: "0px 20px",
                                            lineHeight: "40px",
                                            color: "#555555",
                                            fontSize: "16px",
                                            fontWeight: 500
                                        }}>
                                        <Link to={"/search/environmental/anything"}>
                                            标准液体
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item
                                        style={{
                                            padding: "0px 20px",
                                            lineHeight: "40px",
                                            color: "#555555",
                                            fontSize: "16px",
                                            fontWeight: 500
                                        }}>
                                        <Link to={"/search/food/anything"}>
                                            标准气体
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item
                                        style={{
                                            padding: "0px 20px",
                                            lineHeight: "40px",
                                            color: "#555555",
                                            fontSize: "16px",
                                            fontWeight: 500
                                        }}>
                                        <Link to={"/search/other/anything"}>
                                            其他
                                        </Link>
                                    </Menu.Item>
                                </Menu>
                            </Col>
                            <Col span={14}>
                                <Carousel autoplay>
                                    <img src={ImageSlider}/>
                                    <img src={ImageSlider2}/>
                                </Carousel>
                            </Col>
                            <Col span={5}>
                                {userName === null ?
                                    <div className="user-container"
                                         style={{
                                             height: 180,
                                             background: "#F7F7F7",
                                             paddingTop: 20,
                                             paddingBottom: 10
                                         }}>
                                        <div style={{
                                            width: "175px",
                                            margin: "0 auto",
                                            marginBottom: 15,
                                            height: 50,
                                            overflow: "hidden"
                                        }}>
                                            <Avatar size={50} icon="user"
                                                    style={{display: "inline-block", verticalAlign: "top"}}/>
                                            <div style={{
                                                color: "#333",
                                                marginLeft: 15,
                                                display: "inline-block",
                                            }}>
                                                <div style={{fontSize: 16, height: 25, lineHeight: "25px"}}>
                                                    你好,未登录
                                                </div>
                                                <div style={{
                                                    color: "#929299",
                                                    fontSize: 14,
                                                    height: 25,
                                                    lineHeight: "25px"
                                                }}>
                                                    欢迎下单和咨询
                                                </div>
                                            </div>
                                        </div>
                                        <Link to={"/login"} style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            height: "30px",
                                            lineHeight: "30px",
                                            background: "#0088cc",
                                            color: "#ffffff",
                                            cursor: "pointer",
                                            width: "175px",
                                            margin: "0 auto",
                                            textAlign: "center",
                                            marginBottom: "10px",
                                            display: "block"
                                        }}>
                                            立即登录
                                        </Link>
                                        <Link to={"/register"} style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            height: "30px",
                                            lineHeight: "30px",
                                            background: "#bbbbbb",
                                            color: "#ffffff",
                                            cursor: "pointer",
                                            width: "175px",
                                            margin: "0 auto",
                                            textAlign: "center",
                                            display: "block"
                                        }}>
                                            免费注册
                                        </Link>
                                    </div> :
                                    <div className="user-container"
                                         style={{
                                             height: 180,
                                             background: "#F7F7F7",
                                             paddingTop: 20,
                                             paddingBottom: 10
                                         }}>
                                        <div style={{
                                            width: "175px",
                                            margin: "0 auto",
                                            marginBottom: 15,
                                            height: 50,
                                            overflow: "hidden"
                                        }}>
                                            {
                                                userUrl && userUrl.length > 0 ?
                                                    <Avatar size={50} src={userUrl}
                                                            style={{display: "inline-block", verticalAlign: "top"}}/> :
                                                    <Avatar size={50} icon="user"
                                                            style={{display: "inline-block", verticalAlign: "top"}}/>
                                            }
                                            <div style={{
                                                color: "#333",
                                                marginLeft: 15,
                                                display: "inline-block",
                                            }}>
                                                <div style={{
                                                    fontSize: 16,
                                                    height: 25,
                                                    lineHeight: "25px",
                                                    width: 100,
                                                    overflow: "hidden"
                                                }}>
                                                    你好,{userName}</div>
                                                <div style={{
                                                    color: "#929299",
                                                    fontSize: 14,
                                                    height: 25,
                                                    lineHeight: "25px"
                                                }}>
                                                    欢迎下单和咨询
                                                </div>
                                            </div>
                                        </div>
                                        <Link to={"/payment"} style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            height: "30px",
                                            lineHeight: "30px",
                                            background: "#0088cc",
                                            color: "#ffffff",
                                            cursor: "pointer",
                                            width: "175px",
                                            margin: "0 auto",
                                            textAlign: "center",
                                            marginBottom: "10px",
                                            display: "block"
                                        }}>
                                            购物车
                                        </Link>
                                        <Link to={"/center/order"} style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            height: "30px",
                                            lineHeight: "30px",
                                            background: "#bbbbbb",
                                            color: "#ffffff",
                                            cursor: "pointer",
                                            width: "175px",
                                            margin: "0 auto",
                                            textAlign: "center",
                                            display: "block"
                                        }}>
                                            我的订单
                                        </Link>
                                    </div>}
                                <div className="online">
                                    <h4>致正 <span>科技</span></h4>
                                    <p>官方自营，价格透明</p>
                                    <span className="btn-round"
                                          onClick={() => this.props.history.push(
                                              "/search/all/anything"
                                          )}>开始采购</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="shipping-container">
                    <div className="shipping-wrapper">
                        <ul>
                            <li>
                                <div className="media-left">
                                    <Icon type="car" style={{
                                        fontSize: "46px",
                                        color: "#0088cc",
                                        lineHeight: "46px"
                                    }}/>
                                </div>
                                <div className="media-body">
                                    <h5>服务齐全,一站全包</h5>
                                    {/*<span>xxxxxxxxxxxxxx</span>*/}
                                </div>
                            </li>
                            <li>
                                <div className="media-left">
                                    <Icon type="dollar" style={{
                                        fontSize: "46px",
                                        color: "#0088cc",
                                        lineHeight: "46px"
                                    }}/>
                                </div>
                                <div className="media-body">
                                    <h5>快捷受理，高效省时</h5>
                                    {/*<span>xxxxxxxxxxxxxx</span>*/}
                                </div>
                            </li>
                            <li>
                                <div className="media-left">
                                    <Icon type="phone" style={{
                                        fontSize: "46px",
                                        color: "#0088cc",
                                        lineHeight: "46px"
                                    }}/>
                                </div>
                                <div className="media-body">
                                    <h5>专业服务,国际权威</h5>
                                    {/*<span>xxxxxxxxxxxxxx</span>*/}
                                </div>
                            </li>
                            <li>
                                <div className="media-left">
                                    <Icon type="credit-card" style={{
                                        fontSize: "46px",
                                        color: "#0088cc",
                                        lineHeight: "46px"
                                    }}/>
                                </div>
                                <div className="media-body">
                                    <h5>官方自营,价格透明</h5>
                                    {/*<span>xxxxxxxxxxxxxx</span>*/}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                {/*<div className="shop-window-container">*/}
                <ShopWindowComponent title="标准液体" productType={"标准液体"}/>
                <ShopWindowComponent title="标准气体" productType={"标准气体"}/>
                {/*</div>*/}
            </Content>
            <PageFooter/>
        </Layout>
    }
}
