/**
 * Created by Administrator on 2020/4/8 0008.
 */
import React from 'react';
import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Select, Icon, Upload, Card, Layout, Skeleton, message
} from 'antd';
import "./../css/center.css"
import ImgCrop from "antd-img-crop";
import * as ApiService from "../api/apiService"
import TopBanner from "../component/layout/TopBanner";
import NavigationBar from "../component/layout/NavigationBar";
import SearchBar from "../component/layout/SearchBar";
import CenterMenu from "../component/center/CenterMenu";
import PageFooter from "../component/layout/PageFooter";
import * as OSSUtils from "../utils/OSSUtils";
const {Option} = Select;
const {Header, Content, Footer} = Layout;
class UserAccountPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            userUpdating: false,
            user: {}
        }
    }


    componentDidMount() {
        this.setState({
            loading: true
        });
        ApiService.getAccount().then((result) => {
            this.setState({
                loading: false,
                user: result
            });
        }).catch((error) => {
        })
    }

    handleUpload = (param, file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            // 使用ossupload覆盖默认的上传方法
            OSSUtils.upload(this, '/', file).then(data => {
                const url = data.url;
                const user = this.state.user;
                user.userUrl = url;
                this.setState({
                    user
                });
                this.props.form.setFieldsValue({userUrl: url});
            })
        };
        return false; // 不调用默认的上传方法
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err)
                return;
            this.setState({
                userUpdating: true
            });
            ApiService.updateAccount(values).then((result) => {
                this.setState({
                    userUpdating: false,
                });
                message.success("修改成功")
            }).catch((error) => {
                this.setState({
                    userUpdating: false,
                });
                message.error(error.message)
            })
        });
    };

    render() {
        const {loading, user, userUpdating} = this.state;
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 4},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 18},
                sm: {span: 18},
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 8,
                    offset: 4,
                },
            },
        };
        const uploadButton = (
            <div style={{
                width: 100, height: 100, display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}>
                <Icon type="plus"/>
                <div className="ant-upload-text">选择图片</div>
            </div>
        );
        return <Layout className="layout center">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                <NavigationBar selectedKey="home"/>
            </Header>
            <Content overlay="" className="body">
                <div className="center-container">
                    <div className="center-wrapper">
                        <Row gutter={24}>
                            <Col span={5}>
                                <CenterMenu selectMenu="account"/>
                            </Col>
                            <Col span={19}>
                                <div className="center-body">
                                    <Card title="用户信息" style={{minHeight: "calc(100vh - 223px - 150px - 80px)"}}>
                                        {loading ?
                                            <Skeleton />
                                            :
                                            <Row>
                                                <Col span={16}>
                                                    <Form {...formItemLayout} onSubmit={this.onSubmit}>
                                                        <Form.Item label="头像" style={{marginBottom: 0}}>
                                                            {getFieldDecorator('userUrl', {
                                                                rules: [{required: false, message: '请上传商家图片'}],
                                                            })(
                                                                <ImgCrop width={150} height={150}>
                                                                    <Upload
                                                                        name="avatar"
                                                                        listType="picture-card"
                                                                        className="avatar-uploader"
                                                                        beforeUpload={this.handleUpload.bind(this, "userUrl")}
                                                                        showUploadList={true}
                                                                    >
                                                                        {user.userUrl ?
                                                                            <div>
                                                                                <img style={{width: 100, height: 100}}
                                                                                     src={user.userUrl}/>
                                                                            </div> : uploadButton}
                                                                    </Upload>
                                                                </ImgCrop>
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item label="帐号">
                                                            {getFieldDecorator('userAccount', {
                                                                rules: [{required: false, message: ''}],
                                                                initialValue: user.userAccount
                                                            })(<Input
                                                                addonBefore={<Icon type="phone"/>}
                                                                style={{width: '100%'}} disabled={true}/>)}
                                                        </Form.Item>
                                                        <Form.Item label="昵称">
                                                            {getFieldDecorator('userNickName', {
                                                                rules: [
                                                                    {
                                                                        required: false,
                                                                        message: '请输入商家名称',
                                                                    },

                                                                ],
                                                                initialValue: user.userNickName
                                                            })(<Input disabled={true}/>)}
                                                        </Form.Item>
                                                        <Form.Item label="性别">
                                                            {getFieldDecorator('userGender', {
                                                                rules: [{required: false, message: '请选择商家类型'}],
                                                                initialValue: user.userGender
                                                            })(<Select>
                                                                <Option value="男">男</Option>
                                                                <Option value="女">女</Option>
                                                            </Select>)}
                                                        </Form.Item>
                                                        <Form.Item label="所属企业">
                                                            {getFieldDecorator('userCompany', {
                                                                rules: [
                                                                    {
                                                                        required: false,
                                                                        message: '所属企业',
                                                                    },
                                                                ],
                                                                initialValue: user.userCompany
                                                            })(<Input/>)}
                                                        </Form.Item>
                                                        <Form.Item {...tailFormItemLayout}>
                                                            <Button type="primary" loading={userUpdating}
                                                                    htmlType={"submit"}>
                                                                保存修改
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        }

                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

            </Content>
            <PageFooter/>
        </Layout>


    }
}

export default UserAccountPage = Form.create()(UserAccountPage);