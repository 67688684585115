/**
 * Created by Administrator on 2020/4/9 0009.
 */
/**
 * Created by Administrator on 2020/4/2 0002.
 */
/**
 * Created by Administrator on 2020/3/31 0031.
 */
/**
 * Created by Administrator on 2020/3/30 0030.
 */
import React from 'react';
import {
    Button,
    Form, Icon, Input, Result,
} from "antd";
import {Link} from "react-router-dom";
export default class RegisterStep3Component extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }



    render() {
        return <div className="register-form">
            <Result
                status="success"
                title="您已经成功注册为凯乐检测会员"
                extra={
                    <Link to="/login" className="register-next-btn"
                          style={{display: "block", width: "50%", margin: "0 auto", height: 40, lineHeight: "40px"}}>
                        立即登录
                    </Link>
                }
            />
        </div>
    }
}