/**
 * Created by Administrator on 2020/4/9 0009.
 */
/**
 * Created by Administrator on 2020/4/2 0002.
 */
/**
 * Created by Administrator on 2020/3/31 0031.
 */
/**
 * Created by Administrator on 2020/3/30 0030.
 */
import React from 'react';
import {
    Form, Icon, Input, message
} from "antd";
import * as ApiService from "./../../api/apiService"

class RegisterStep2Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountRegistering: false
        }
    }

    componentDidMount() {
    }

    onNext = () => {
        this.props.form.validateFields((err, values) => {
            if (err)
                return;
            console.log(this.props.phone)
            // this.props.onNext(values)
            this.setState({
                accountRegistering: true,
            });
            values.phone = this.props.phone;
            ApiService.register(values).then((result) => {
                this.setState({
                    accountRegistering: false,
                });
                this.props.onNext("")
            }).catch((error) => {
                const errorDescription = error.message;
                message.error(errorDescription);
                this.setState({
                    accountRegistering: false,
                });
            })
        });
    };


    render() {
        const {getFieldDecorator} = this.props.form;
        const {accountRegistering} = this.state;
        return <Form className="register-form">
            <Form.Item>
                {getFieldDecorator('nickName', {
                    rules: [{required: true, message: '请设置正确的用户名'}],
                })(<Input size="large" placeholder="请输入用户名,不超过6个字符"
                          addonBefore={<Icon type="user"/>}/>)}
            </Form.Item>
            <Form.Item>
                {getFieldDecorator('password', {
                    rules: [{required: true, message: '请设置正确的密码'}],
                })(<Input.Password size="large" placeholder="请输入密码,不超过12个字符"
                                   addonBefore={<Icon type="lock"/>}/>)}
            </Form.Item>
            <Form.Item>
                {getFieldDecorator('passwordAgain', {
                    rules: [{required: true, message: '请设置正确的密码'}],
                })(<Input.Password size="large" placeholder="请再次输入密码"
                                   addonBefore={<Icon type="lock" theme="filled"/>}/>)}
            </Form.Item>
            <Form.Item>
                <div className="register-next-btn" onClick={this.onNext}>
                    {accountRegistering ? <Icon type="loading"/> : "立即注册"}
                </div>
            </Form.Item>
        </Form>
    }
}

export default RegisterStep2Component = Form.create()(RegisterStep2Component);