/**
 * Created by Administrator on 2020/4/3 0003.
 */
/**
 * Created by Administrator on 2020/3/31 0031.
 */
import React from 'react';
import "./../css/search.css"
import {Layout, Breadcrumb, Pagination, Skeleton, Table, Button, Spin, Icon, message, Row, Col} from "antd";
import {Link} from "react-router-dom";
// import ImageShopWindow from "./../images/item-img-1-12.jpg";
import SearchBar from "../component/layout/SearchBar";
import NavigationBar from "../component/layout/NavigationBar";
import TopBanner from "../component/layout/TopBanner";
import * as ApiService from "../api/apiService";
import PageFooter from "../component/layout/PageFooter";
import moment from "moment";

const {Header, Content, Footer} = Layout;
const ButtonGroup = Button.Group;

class SearchPage extends React.Component {
    constructor(props) {
        super(props);
        const paramType = this.props.match.params.type;
        const keyword = this.props.match.params.keyword;
        let productTypeName;
        if (paramType === "all") {
            productTypeName = "全部";
        } else if (paramType === "food") {
            productTypeName = "标准气体";
        } else if (paramType === "environmental") {
            productTypeName = "标准液体";
        } else {
            productTypeName = "其他";
        }
        this.state = {
            productTypeName,
            page: 1,
            pageSize: 12,
            products: [],
            loading: false,
            totalPage: 1,
            keyword: keyword,
            cartLoading: false
        }
    }

    componentDidMount() {
        this.searchProduct(1)
    }

    onPageChange = (page) => {
        this.searchProduct(page)
    };

    searchProduct(page) {
        this.setState({
            loading: true
        });
        const {productTypeName, pageSize, keyword} = this.state;
        const params = {
            productType: productTypeName,
            page: page,
            pageSize: pageSize,
            keyword: keyword
        };
        ApiService.searchProducts(params).then((result) => {
            const list = result.list;
            const totalPage = result.pages;
            const page = result.pageNum;
            list.forEach((item) => {
                item.productBuyNumb = 1
                item.productValidValue = JSON.parse(item.productValidValue)
                item.productSelectVaildValue = 0
            })
            this.setState({
                loading: false,
                totalPage: totalPage,
                page: page,
                products: list
            });
        }).catch((error) => {
        })
    }

    addCart = (product, event) => {
        this.setState({
            cartLoading: true
        });
        const params = {
            cartProductId: product.productId,
            cartProductCount: product.productBuyNumb,
            cartProductValidValue: product.productSelectVaildValue
        };
        ApiService.addCart(params).then((result) => {
            this.setState({
                cartLoading: false
            });
            message.success("加入购物车成功");
        }).catch((error) => {
            this.setState({
                cartLoading: false
            });
            if (error.message === "Request failed with status code 403") {
                this.props.history.push({
                    pathname: "/login",
                })
            } else {
                message.error(error.message);
            }
        })
    }

    render() {
        const {products, loading, totalPage, page, productTypeName, cartLoading} = this.state;
        // let shopWindows;
        // if (loading) {
        //     //设置要显示加载效果的橱窗个数
        //     const list = [];
        //     for (let i = 0; i < 9; i++) {
        //         list.push(i)
        //     }
        //     shopWindows = list.map((item, index) => {
        //         return <Col span={4} key={index}>
        //             <div className="shop-window-item">
        //                 <Skeleton active/>
        //             </div>
        //         </Col >
        //     })
        // } else {
        //     shopWindows = products.map((item, index) => {
        //         const urls = JSON.parse(item.productUrl);
        //         return <Col span={4} key={index}>
        //             <Link to={{pathname: "/product/" + item.productId, state: {productTypeName: item.productTypeName}}}>
        //                 <div className="shop-window-item">
        //                     <img className="img-responsive" src={urls[0]} alt=""/>
        //                     <span className="tag">{item.productTypeName}</span>
        //                     <p className="title">{item.productName}
        //                     </p>
        //                     <p className="rev">
        //                         <span>{item.productIntroduction}</span>
        //                     </p>
        //                     <div className="price">￥ {(item.productPrice ).toFixed(2)}</div>
        //                     <span className="cart-btn">
        //                                     <Icon type="shopping-cart"/>
        //                                 </span>
        //                 </div>
        //             </Link>
        //         </Col >
        //     })
        // }
        const columns = [
            {
                title: '产品名称',
                dataIndex: 'productName',
                key: 'productName',
                render: (val, record) => <div style={{width: 100}}>
                    <Link to={{
                        pathname: "/product/" + record.productId,
                        state: {productTypeName: record.productTypeName}
                    }}>{val}</Link>
                </div>,
            },
            {
                title: '产品编号',
                dataIndex: 'productNum',
                key: 'productNum',
            }, {
                title: '国标号',
                dataIndex: 'productCas',
                key: 'productCas',
            },
            {
                title: '有效期',
                dataIndex: 'productValidDate',
                key: 'productValidDate',
                render: val => <span>{moment(val).format("YYYY-MM-DD")}</span>
            }, {
                title: '标准值',
                dataIndex: 'productValidValue',
                key: 'productValidValue',
                render: (val, record) => {
                    const element = val.map((item, index) => {
                        if (index === record.productSelectVaildValue) {
                            return <span key={item} style={{
                                padding: "6px",
                                color: "#0a95da"
                            }}>{item}</span>
                        }
                        return <span key={item} style={{
                            padding: "6px",
                        }} onClick={() => {
                            record.productSelectVaildValue = index;
                            this.forceUpdate()
                        }}>{item}</span>
                    })
                    return <div style={{width: 80}}>
                        {element}
                    </div>;
                }
            }, {
                title: '规格',
                dataIndex: 'productSpec',
                key: 'productSpec',
            }, {
                title: '价格',
                dataIndex: 'productPrice',
                key: 'productPrice',
                render: val => {
                    return <span>￥{(val).toFixed(2)}</span>
                }
            }, {
                title: '库存',
                dataIndex: 'productStock',
                key: 'productStock',
                width: 60,
                render: val => {
                    if (val > 0) {
                        return <span>现货</span>
                    } else {
                        return <span>无货</span>
                    }
                }
            }, {
                title: '品牌',
                dataIndex: 'productBrand',
                key: 'productBrand',
            }, {
                title: '数量',
                dataIndex: 'productBuyNumb',
                key: "productBuyNumb",
                render: (val, record) => <div style={{width: 92}}>
                    <span style={{padding: 6, background: "#EAEAEA", cursor: "pointer"}}
                          onClick={() => {
                              if (record.productBuyNumb === 1)
                                  return
                              record.productBuyNumb--;
                              this.forceUpdate();
                          }}>
                        <Icon type="minus"/>
                    </span>
                    <span style={{
                        borderTop: "1px solid #EAEAEA",
                        borderBottom: "1px solid #EAEAEA",
                        paddingTop: 4,
                        paddingBottom: 4,
                        width: 40,
                        textAlign: "center",
                        display: "inline-block"
                    }}>{val}</span>
                    <span style={{padding: 6, background: "#EAEAEA", cursor: "pointer"}} onClick={() => {
                        if (record.productBuyNumb >= record.productStock)
                            return
                        record.productBuyNumb++;
                        this.forceUpdate();
                    }}>
                           <Icon type="plus"/>
                    </span>

                </div>
            }, {
                title: '加入购物车',
                dataIndex: 'option',
                key: 'option',
                render: (val, record) => {
                    if (record.productStock > 0) {
                        return <Button type="primary" icon="shopping-cart" onClick={this.addCart.bind(this, record)}>
                        </Button>
                    } else {
                        return <Button disabled={true} type="primary" icon="shopping-cart"
                                       onClick={this.addCart.bind(this, record)}>

                        </Button>
                    }

                }
            },
        ];
        return <Layout className="layout search">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                <NavigationBar/>
            </Header>
            <Content overlay="" className="body">
                <div className="search-result-container">
                    <div className="search-result-wrapper">
                        <div style={{padding: "8px 0px", borderBottom: "1px solid #e5e5e5"}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to={"/shop"}>商城</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {productTypeName}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
                <Spin tip="加载中..." spinning={cartLoading}
                      indicator={<Icon type="loading" style={{fontSize: 24}} spin/>}>
                    <div className="shop-window-container">
                        <div className="shop-window-wrapper" style={{paddingTop: 0}}>
                            <div className="shop-window-body">
                                {!loading && products.length > 0 &&
                                <Table className="products-table" columns={columns} rowKey={"productId"}
                                       pagination={false} dataSource={products}/>}
                                {!loading && products.length === 0 &&
                                <div style={{paddingTop: 40, width: 400, margin: "0 auto"}}>
                                    <div style={{fontSize: 28}}>没有找到<span
                                        style={{color: "red"}}>{this.props.match.params.keyword === "anything" ? "" : this.props.match.params.keyword}</span>相关的商品
                                    </div>
                                    <div style={{marginTop: 20}}>您可以:</div>
                                    <div style={{marginTop: 16}}>1、尝试直接拨打电话18280000124 17628662300 15208133106
                                    </div>
                                    <div style={{marginTop: 16}}>
                                        <span>2、通过以下方式继续访问</span>
                                        <Link to="/shop" style={{
                                            color: "#fff",
                                            padding: "6px 12px",
                                            background: "#0088cc",
                                            marginLeft: 6,
                                            borderRadius: 4
                                        }}>返回首页</Link>
                                    </div>
                                </div>
                                }
                            </div>
                            <div style={{textAlign: "center", marginTop: 40}}>
                                <Pagination current={page} total={totalPage} defaultPageSize={1}
                                            onChange={this.onPageChange}/>
                            </div>
                        </div>
                    </div>
                </Spin>
            </Content>
            <PageFooter/>
        </Layout>
    }
}

export default (props) => <SearchPage {...props} key={props.location.pathname}/>
