/**
 * Created by Administrator on 2020/4/13 0013.
 */
/**
 * Created by Administrator on 2020/4/2 0002.
 */
/**
 * Created by Administrator on 2020/3/31 0031.
 */
/**
 * Created by Administrator on 2020/3/30 0030.
 */
import React from 'react';
import {
    Button,
    Layout, message, Result
} from "antd";
import SearchBar from "../component/layout/SearchBar";
import NavigationBar from "../component/layout/NavigationBar";
import TopBanner from "../component/layout/TopBanner";
import PageFooter from "../component/layout/PageFooter";
const {Header, Content, Footer} = Layout;

export  default class PaymentSuccessfulPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {

    }

    render() {
        return <Layout className="layout">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                <NavigationBar/>
            </Header>
            <Content overlay="" className="body">
                <div style={{
                    minHeight: "calc(100vh - 223px - 150px)",
                    background: "#fff",
                    paddingTop: "calc((100vh - 223px - 150px - 299px)/2)"
                }}>
                    <Result
                        status="success"
                        title="提交订单成功~"
                        extra={<Button type="primary" onClick={() => {
                            this.props.history.push("/center/order")
                        }
                        }>查看订单</Button>}
                    />
                </div>
            </Content>
            <PageFooter/>
        </Layout>
    }
}