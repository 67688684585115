/**
 * Created by Administrator on 2020/4/8 0008.
 */
/**
 * Created by Administrator on 2020/4/8 0008.
 */
import React from 'react';
import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Select, Icon, Card, Modal, Cascader,
    Layout, Meta,
    message, Skeleton, Spin, Result
} from 'antd';
import "./../css/center.css"
import * as ApiService from "../api/apiService"
import * as CityUtils from "../utils/CityUtils";
import SearchBar from "../component/layout/SearchBar";
import TopBanner from "../component/layout/TopBanner";
import NavigationBar from "../component/layout/NavigationBar";
import CenterMenu from "../component/center/CenterMenu";
import PageFooter from "../component/layout/PageFooter";
const {Option} = Select;
const {Header, Content, Footer} = Layout;
const antdDistrict = [];
const districtData = CityUtils.getCountry();
Object.keys(districtData).forEach((index) => {
    let itemLevel1 = {};
    let itemLevel2 = {};
    itemLevel1.value = districtData[index].name;
    itemLevel1.label = districtData[index].name;
    itemLevel1.children = [];
    let data = districtData[index].cities;
    Object.keys(data).forEach((index) => {
        itemLevel2.value = data[index].name;
        itemLevel2.label = data[index].name;
        itemLevel2.children = [];
        let data2 = data[index].districts;
        let itemLevel3 = {};
        itemLevel3.children = [];
        Object.keys(data2).forEach((index) => {
            itemLevel3.value = data2[index];
            itemLevel3.label = data2[index];
            itemLevel2.children.push(itemLevel3);
            itemLevel3 = {};
        });
        itemLevel1.children.push(itemLevel2);
        itemLevel2 = {};
    });
    antdDistrict.push(itemLevel1)
});
class UserAddressPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            addressInserting: false,
            addressGetting: false,
            addressRemoving: false,
            addresses: []
        }
    }

    componentDidMount() {
        this.getAddresses()
    }

    onClickAddAddress = () => {
        this.setState({
            visible: true
        })
    };

    onClickRemoveAddress = (addressId, event) => {
        this.setState({
            addressRemoving: true,
        });
        ApiService.removeAddresses({addressId: addressId}).then((result) => {
            this.setState({
                addressRemoving: false,
                visible: false
            });
            this.getAddresses()
        }).catch((error) => {
            this.setState({
                addressRemoving: false
            });
            const errorDescription = error.message;
            message.error(errorDescription);
        })
    };

    getAddresses = () => {
        this.setState({
            addressGetting: true
        });
        ApiService.getAddresses().then((result) => {
            this.setState({
                addresses: result,
                addressGetting: false
            })
        }).catch((error) => {
        })
    };

    handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (err)
                return;
            this.setState({
                addressInserting: true,
            });
            ApiService.addAddress(values).then((result) => {
                this.setState({
                    addressInserting: false,
                    visible: false
                });
                this.getAddresses()
            }).catch((error) => {
                this.setState({
                    addressInserting: false
                });
                const errorDescription = error.message;
                message.error(errorDescription);
            })
        })
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        })
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {addressInserting, addresses, visible, addressGetting, addressRemoving} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: {span: 4},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 18},
                sm: {span: 18},
            },
        };
        let contentComponent;
        if (addressGetting) {
            contentComponent = <Row gutter={[24, 24]}>
                <Col span={8}>
                    <Skeleton />
                </Col>
                <Col span={8}>
                    <Skeleton />
                </Col>
                <Col span={8}>
                    <Skeleton />
                </Col>
            </Row>
        } else if (!addressGetting && addresses.length === 0) {
            contentComponent = <Result
                icon={<Icon type="smile" theme="twoTone"/>}
                title="还没有添加收货地址~"
                extra={<Button type="primary" onClick={this.onClickAddAddress}>添加收获地址</Button>}
            />
        } else if (!addressGetting && addresses.length > 0) {
            contentComponent = <Row gutter={[24, 24]}>
                {addresses.map((item, index) => {
                    const area = item.addressArea.replace("[", "").replace("]", "");
                    return <Col key={index} span={8}>
                        <Spin spinning={addressRemoving}>
                            <div className="logistics-address-item"
                                 onClick={this.onClickRemoveAddress.bind(this, item.addressId)}>
                                <div className="logistics-address-item-header">
                                    <span>{item.addressContactName}</span>
                                    <span>{item.addressContactPhone}</span>
                                </div>
                                <div className="logistics-address-item-body">
                                    <p>{item.addressCompany}</p>
                                    <p>{area}</p>
                                    <p>{item.addressStreet}</p>
                                </div>
                            </div>
                        </Spin>
                    </Col>
                })}
            </Row>
        }
        return <Layout className="layout center">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                <NavigationBar selectedKey="home"/>
            </Header>
            <Content overlay="" className="body">
                <div className="center-container">
                    <div className="center-wrapper">
                        <Row gutter={24}>
                            <Col span={5}>
                                <CenterMenu selectMenu="address"/>
                            </Col>
                            <Col span={19}>
                                <div className="center-body">
                                    <Card title="收货地址" style={{minHeight: "calc(100vh - 223px - 150px - 80px)"}}
                                          extra={<span style={{color: "#1890ff", cursor: "pointer"}}
                                                       onClick={this.onClickAddAddress}>添加收货地址</span>}>
                                        {contentComponent}
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
            <PageFooter/>
            <Modal
                destroyOnClose={true}
                title={"添加收货地址"}
                visible={visible}
                onOk={this.handleOk}
                centered={true}
                width={600}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        取消
                    </Button>,
                    <Button key="submit" type="primary" loading={addressInserting}
                            onClick={this.handleOk}>
                        确认添加
                    </Button>,
                ]}
            >
                <div>
                    <Form {...formItemLayout}>
                        <Form.Item label="收货人">
                            {getFieldDecorator('addressContactName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '收货人',
                                    },
                                ],
                            })(<Input addonBefore={<Icon type="user"/>}
                                      placeholder="收货人"/>)}
                        </Form.Item>
                        <Form.Item label="联系电话">
                            {getFieldDecorator('addressContactPhone', {
                                rules: [
                                    {
                                        required: true,
                                        message: '联系电话',
                                    },
                                    {
                                        pattern: /^1[3|4|5|7|8][0-9]\d{8}$/, message: '请输入正确的联系电话'
                                    }
                                ],
                            })(<Input addonBefore={<Icon type="phone"/>}
                                      placeholder="联系电话"/>)}
                        </Form.Item>
                        <Form.Item label="公司名称">
                            {getFieldDecorator('addressCompany', {
                                rules: [
                                    {
                                        required: true,
                                        message: '联系电话',
                                    },
                                ],
                            })(<Input addonBefore={<Icon type="home"/>}
                                      placeholder="公司名称"/>)}
                        </Form.Item>
                        <Form.Item label="收货地址">
                            {getFieldDecorator('addressArea', {
                                rules: [
                                    {
                                        required: true,
                                        message: '收货地址',
                                    },
                                ],
                            })(<Cascader placeholder="收货地址" options={antdDistrict}/>)}
                        </Form.Item>
                        <Form.Item label="街道地址" style={{marginBottom: 0}}>
                            {getFieldDecorator('addressStreet', {
                                rules: [
                                    {
                                        required: true,
                                        message: '收货地址',
                                    },
                                ],
                            })(<Input
                                placeholder="街道地址"/>)}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </Layout>

    }
}
export default UserAddressPage = Form.create()(UserAddressPage);