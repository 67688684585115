/**
 * Created by Administrator on 2020/4/9 0009.
 */
/**
 * Created by Administrator on 2020/4/3 0003.
 */
/**
 * Created by Administrator on 2020/4/3 0003.
 */
import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {Dropdown, Icon, Menu} from "antd";

class CenterMenu extends React.Component {
    constructor(props) {
        super(props)
    }

    onMenuClick = (url, event) => {
        this.props.history.push(url);
    };

    render() {
        return <Menu
            className="center-menu"
            defaultSelectedKeys={this.props.selectMenu}
            mode="inline"
            theme="dark"
        >
            <Menu.Item key="account" onClick={this.onMenuClick.bind(this, "/center/account")}
                       style={{height: 50, lineHeight: "50px"}}>
                <Icon type="user"/>
                <span style={{fontSize: 16}}>
                    用户信息
                </span>
            </Menu.Item>
            <Menu.Item key="order" onClick={this.onMenuClick.bind(this, "/center/order")}
                       style={{height: 50, lineHeight: "50px"}}>
                <Icon type="unordered-list"/>
                <span style={{fontSize: 16}}>
                    我的订单
                </span>
            </Menu.Item>
            <Menu.Item key="address" onClick={this.onMenuClick.bind(this, "/center/address")}
                       style={{height: 50, lineHeight: "50px"}}>
                <Icon type="file-sync"/>
                <span style={{fontSize: 16}}>
                    收货地址
                </span>
            </Menu.Item>
            <Menu.Item key="password" onClick={this.onMenuClick.bind(this, "/center/password")}
                       style={{height: 50, lineHeight: "50px"}}>
                <Icon type="lock"/>
                <span style={{fontSize: 16}}>
                    修改密码
                </span>
            </Menu.Item>
        </Menu>
    }
}
export default withRouter(CenterMenu);