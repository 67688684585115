/**
 * Created by Administrator on 2019/8/4 0004.
 */
import moment from 'moment';
import oss from 'ali-oss';
const client = (self) => {
    return new oss({
        accessKeyId: "LTAIcIe2c7fLfMio",
        accessKeySecret: "YF8KFYbvKOwYTQSmVr2XMofWzSswc7",
        region: 'oss-cn-chengdu', //
        bucket: 'haokeyuan-commodity',//
    });
};

const uploadPath = (path, file) => {
    // 上传文件的路径，使用日期命名文件目录
    return `${moment().format('kltesting')}/${file.uid}.${file.type.split("/")[1]}`
};

const uploadHtmlPath = (path, name) => {
    // 上传文件的路径，使用日期命名文件目录
    return `${moment().format('YYYYMMDD')}/${moment().format('YYYYMMDD hh:mm:ss')}`
};

export function upload(self, path, file) {
    const url = uploadPath(path, file);
    return new Promise((resolve, reject) => {
        client(self).put(url, file).then(data => {
            resolve(data);
        }).catch(error => {
            reject(error)
        })
    })
}

export function uploadHtml(self, path, name) {
    const url = uploadHtmlPath(path, name);
    return new Promise((resolve, reject) => {
        const oss = client(self);
        oss.put(url, new Blob([name], {type: 'text/plain'})).then(data => {
            console.log(data);
            resolve(data);
        }).catch(error => {
            reject(error)
        })
    })
}
export function getObject(self, key) {
    return new Promise((resolve, reject) => {
        client(self).get(key).then(data => {
            console.log(data)
            resolve(data);
        }).catch(error => {
            console.log(error);
            reject(error)
        });
    })
}