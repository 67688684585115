/**
 * Created by Administrator on 2020/4/16 0016.
 */
/**
 * Created by Administrator on 2020/4/16 0016.
 */
import React from 'react';
import {Col, Divider, Icon, Layout, Pagination, Row, Tabs} from "antd";
import "./../css/newsinfo.css"
import TopBanner from "../component/layout/TopBanner";
import SearchBar from "../component/layout/SearchBar";
import NavigationBar from "../component/layout/NavigationBar";
import PageFooter from "../component/layout/PageFooter";
import moment from "moment";
import * as OSSUtils from "../utils/OSSUtils";
import * as Uint8ArrayUtils from "../utils/Uint8ArrayUtils";
const {Header, Content, Footer} = Layout;
export default class NewsInfoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            html: ""
        }
    }

    componentDidMount = () => {
        const news = this.props.location.state.news;
        OSSUtils.getObject(this, news.newsContentUrl).then((data) => {
            const content = data.content;
            this.setState({
                html: Uint8ArrayUtils.Utf8ArrayToStr(content)
            })
        }).catch(error => {

        })
    };

    render() {
        const {news} = this.props.location.state;
        // 发布时间：2019-10-20 13:12   来源：    作者：
        return <Layout className="layout news-info">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                <NavigationBar selectedKey="news"/>
            </Header>
            <Content overlay="" className="body">
                <div className="news-info-container-top"/>
                <div className="news-info-container">
                    <div className="news-info-wrapper">
                        <h1 style={{
                            textAlign: "center",
                            marginBottom: 20,
                            fontSize:32
                        }}>{news.newsTitle}</h1>
                        <div style={{textAlign: "center", fontSize: 18, marginBottom: 30}}>
                            <span><span style={{marginRight:40}}>发布时间 : {moment(news.newsDate).format("YYYY年MM月DD日 HH:mm:ss")}</span><span>作者 : {news.newsWriter}</span></span>
                        </div>
                        <div dangerouslySetInnerHTML={{__html: this.state.html}}/>
                    </div>
                </div>
            </Content>
            <PageFooter/>
        </Layout>
    }
}