/**
 * Created by Administrator on 2020/4/2 0002.
 */
/**
 * Created by Administrator on 2020/3/31 0031.
 */
/**
 * Created by Administrator on 2020/3/30 0030.
 */
import React from 'react';
import "./../css/register.css"
import {
    Layout,
    Steps, Tabs,
} from "antd";
import SearchBar from "../component/layout/SearchBar";
import NavigationBar from "../component/layout/NavigationBar";
import TopBanner from "../component/layout/TopBanner";
import RegisterStep1Component from "../component/register/RegisterStep1Component";
import RegisterStep2Component from "../component/register/RegisterStep2Component";
import RegisterStep3Component from "../component/register/RegisterStep3Component";

import PageFooter from "../component/layout/PageFooter";

const {Header, Content} = Layout;
const {Step} = Steps;
const {TabPane} = Tabs;
export default class RegisterPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            phone: ""
        }
    }

    componentDidMount() {
    }

    onNext = (phone) => {
        const {currentStep} = this.state;
        this.setState({
            currentStep: currentStep + 1,
            phone: phone
        })
        // this.lastStepComponent.setPhone(phone);
    };

    // onSubmit = (values) => {
    //     this.setState({
    //         addressInserting: true,
    //     });
    //     values.phone = this.state.phone;
    //     ApiService.register(values).then((result) => {
    //         const {currentStep} = this.state;
    //         this.setState({
    //             addressInserting: false,
    //             currentStep: currentStep + 1,
    //         })
    //     }).catch((error) => {
    //         console.log(error);
    //         this.setState({
    //             addressInserting: false
    //         });
    //         const errorDescription = error.message;
    //         message.error(errorDescription);
    //     })
    // };

    render() {
        const {currentStep} = this.state;
        return <Layout className="layout register">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                <NavigationBar/>
            </Header>
            <Content overlay="" className="body">
                <div className="form-container">
                    <div className="form-wrapper">
                        <Steps current={currentStep}>
                            <Step title="验证邮箱地址" description=""/>
                            <Step title="填写账号信息"/>
                            <Step title="注册成功"/>
                        </Steps>
                        <Tabs activeKey={String(currentStep)} animated={false} renderTabBar={() => {
                            return <div style={{height: 0}}/>
                        }
                        }>
                            <TabPane tab="" key="0">
                                <RegisterStep1Component onNext={this.onNext}/>
                            </TabPane>
                            <TabPane tab="" key="1">
                                <RegisterStep2Component phone={this.state.phone}
                                                        onNext={this.onNext}/>
                            </TabPane>
                            <TabPane tab="" key="2">
                                <RegisterStep3Component/>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </Content>
            <PageFooter/>
        </Layout>
    }
}