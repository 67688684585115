/**
 * Created by Administrator on 2020/4/9 0009.
 */
/**
 * Created by Administrator on 2020/4/2 0002.
 */
/**
 * Created by Administrator on 2020/3/31 0031.
 */
/**
 * Created by Administrator on 2020/3/30 0030.
 */
import React from 'react';
import {
    Form, Icon, Input, message,
} from "antd";
import * as ApiService from "../../api/apiService";

class ForgetStep1Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            verificationGetting: 0,
            verificationChecking: false
        }
    }

    componentDidMount() {
    }

    onNext = () => {
        this.props.form.validateFields((err, values) => {
            if (err)
                return;

            this.setState({
                verificationChecking: true
            })
            ApiService.checkVerificationCode(values).then((result) => {
                this.setState({
                    verificationChecking: false
                })
                this.props.onNext(values.phone)
            }).catch((error) => {
                console.log(error);
                const errorDescription = error.message;
                message.error(errorDescription);
                this.setState({
                    verificationChecking: false
                })
            })
        });
    };

    getVerificationCode = () => {
        const mail = this.props.form.getFieldValue("phone");
        if (mail.length <= 0)
            return;
        this.setState({
                verificationGetting: 1
            }
        )
        ApiService.forgetVerificationCode({mail: mail}).then((result) => {
            this.setState({
                verificationGetting: 2,
            })
        }).catch((error) => {
            console.log(error);
            const errorDescription = error.message;
            message.error(errorDescription);
            this.setState({
                verificationGetting: 0,
            })
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {verificationGetting, verificationChecking} = this.state;
        let verificationCodeButton;
        if (verificationGetting === 1) {
            verificationCodeButton = <Icon type="loading"/>
        } else if (verificationGetting === 2) {
            verificationCodeButton = <div>已发送验证码</div>
        } else {
            verificationCodeButton = <div style={{cursor: "pointer"}}
                                          onClick={this.getVerificationCode}>获取验证码</div>
        }
        return <Form className="register-form">
            <Form.Item>
                {getFieldDecorator('phone', {
                    rules: [
                        {required: true, message: '请输入正确的邮箱'},
                        {
                            pattern: /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/, message: '请输入正确的邮箱'
                        }],
                })(<Input size="large" placeholder="请输入邮箱"
                          addonBefore={<Icon type="phone" theme="filled"/>}/>)}
            </Form.Item>
            <Form.Item>
                {getFieldDecorator('identifyingCode', {
                    rules: [{required: true, message: '请输入正确的验证码'}],
                })(<Input maxLength={6} size="large" placeholder="请输入验证码"
                          addonBefore={<Icon type="mail" theme="filled"/>}
                          addonAfter={verificationCodeButton}/>)}
            </Form.Item>
            <Form.Item>
                <div className="register-next-btn" onClick={this.onNext}>
                    {verificationChecking ? <Icon type="loading"/> : "下一步"}
                </div>
            </Form.Item>
        </Form>
    }
}

export default ForgetStep1Component = Form.create()(ForgetStep1Component);