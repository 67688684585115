import axios from 'axios';
import {reject, resolve} from "es6-promise";

axios.defaults.withCredentials = true;
export const instance = axios.create({
    baseURL: '/api',
    timeout: 10000,
});
const permitURL = [
    "mail/register_verification_code",
    "mail/forget_verification_code",
    "mail/check_verification_code",
    "news/get_news",
    "product/search_products",
    "product/get_products",
    "product/get_product",
    "user/register",
    "user/login",
    "user/forget_password"
];

export function request(requestData, data) {
    const url = requestData.url;
    const token = localStorage.getItem("token");
    let config;
    if (token === null) {
        config = requestData
    } else if (token != null && permitURL.indexOf(url) > -1) {
        config = requestData
    } else {
        config = requestData;
        config.headers = {'Content-Type': 'application/json', "Authorization": token}
    }
    if (data) {
        if (config.method === 'get') {
            config.params = data;
        } else {
            config.data = data;
        }
    }
    return instance(config)
        .then(res => {
            if (res.data.status !== 1) {
                return new Promise(function (resolve, reject) {
                    reject({message: res.data.message});
                });
            }
            return res.data.result;
        })
        .catch(error => {
            return new Promise(function (resolve, reject) {
                reject({message: error.message});
            });
        });
}
