/**
 * Created by Administrator on 2020/3/31 0031.
 */
/**
 * Created by Administrator on 2020/3/30 0030.
 */
import React from 'react';
import "./../css/product.css"
import {Layout, Icon, Carousel, Row, Col, Breadcrumb, Skeleton, message} from "antd";
import {Link} from "react-router-dom";
import Stepper from "../component/Stepper";
import SearchBar from "../component/layout/SearchBar";
import NavigationBar from "../component/layout/NavigationBar";
import TopBanner from "../component/layout/TopBanner";
import * as ApiService from "./../api/apiService";
import moment from "moment";
import PageFooter from "../component/layout/PageFooter";

const {Header, Content, Footer} = Layout;

export default class ShoppingMallPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null,
            productGetting: true,
            cartAdding: false,
            cart: {
                productValid: 0,
                productNum: 1
            }
        }
    }

    componentDidMount() {
        const params = {
            recordId: this.props.match.params.productId,
        };
        ApiService.getProduct(params).then((result) => {
            const product = result;
            product.productValidValue = JSON.parse(product.productValidValue);
            product.productUrl = JSON.parse(product.productUrl);
            this.setState({
                productGetting: false,
                product: result,
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    onClickPayment = () => {
        const {product, cart} = this.state;
        if (product.productStock===0){
            message.warning("暂无库存")
            return;
        }
        this.setState({
            cartAdding: true
        });
        const params = {
            cartProductId: product.productId,
            cartProductCount: cart.productNum,
            cartProductValidValue: cart.productValid
        };
        ApiService.addCart(params).then((result) => {
            this.setState({
                cartAdding: false
            });
            this.props.history.push({
                pathname: "/payment",
            })
        }).catch((error) => {
            this.setState({
                cartAdding: false
            });
            if (error.message === "Request failed with status code 403") {
                this.props.history.push({
                    pathname: "/login",
                })
            } else {
                message.error(error.message);
            }
        })
    };

    onProductValidClick = (index, event) => {
        const cart = this.state.cart;
        cart.productValid = index;
        this.setState({
            cart: cart
        })
    };

    onProductNumChange = (number) => {
        const cart = this.state.cart;
        cart.productNum = number;
        this.setState({
            cart: cart
        })
    };


    render() {
        const {product, productGetting, cart, productTypeName, cartAdding} = this.state;
        return <Layout className="layout product">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                <NavigationBar/>
            </Header>
            <Content overlay="" className="body">
                <div className="product-detail-container">
                    <div className="product-detail-wrapper">
                        <div style={{padding: "8px 0px", borderBottom: "1px solid #e5e5e5"}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to={"/shop"}>商城</Link>
                                </Breadcrumb.Item>
                                {!productGetting && <Breadcrumb.Item>
                                    <Link to={"/"}>{product.productTypeName}</Link>
                                </Breadcrumb.Item>}
                                {!productGetting && <Breadcrumb.Item>
                                    {product.productName}
                                </Breadcrumb.Item>}
                            </Breadcrumb>
                        </div>
                        <div className="product-detail-body">
                            <Row gutter={40}>
                                <Col span={9}>
                                    <Carousel autoplay>
                                        {!productGetting ? product.productUrl.map((item, index) => {
                                                return <img src={item} key={index}/>
                                            }) :
                                            <div className="preview"/>
                                        }
                                    </Carousel>
                                    <div className="carousel-small">
                                        {!productGetting ?
                                            <Row gutter={12}>
                                                <Col span={3}>
                                                    <div className="pre-btn">
                                                        <Icon type="left"/>
                                                    </div>
                                                </Col>
                                                {product.productUrl.map((item, index) => {
                                                    return <Col span={6} key={index}>
                                                        <img src={item}/>
                                                    </Col>
                                                })}<Col span={3}>
                                                <div className="next-btn">
                                                    <Icon type="right"/>
                                                </div>
                                            </Col>
                                            </Row>
                                            :
                                            <Row gutter={12}>
                                                <Col span={3}>
                                                    <div className="pre-btn">
                                                        <Icon type="left"/>
                                                    </div>
                                                </Col>
                                                <Col span={6}>
                                                    <div className="preview small"/>
                                                </Col>
                                                <Col span={6}>
                                                    <div className="preview small"/>
                                                </Col>
                                                <Col span={6}>
                                                    <div className="preview small"/>
                                                </Col>
                                                <Col span={3}>
                                                    <div className="next-btn">
                                                        <Icon type="right"/>
                                                    </div>
                                                </Col>
                                            </Row>

                                        }

                                    </div>
                                </Col>
                                {productGetting ?
                                    <Col span={15} className="product-detail-content">
                                        <Skeleton active
                                                  paragraph={{
                                                      rows: 12,
                                                      width: [500, 150, 500, 500, 500, 500, 500, 500, 500, 500, 500, 250]
                                                  }}>
                                        </Skeleton>
                                    </Col>
                                    :
                                    <Col span={15} className="product-detail-content">
                                        <h5>{product.productName}</h5>
                                        <div>
                                            <span className="price">￥ {(product.productPrice).toFixed(2)} </span>
                                        </div>
                                        <Row>
                                            <Col span={4}>
                                                品牌
                                            </Col>
                                            <Col span={20}>
                                                {product.productBrand}
                                            </Col>
                                        </Row>
                                        <Row style={{background: "#f4f4f4"}}>
                                            <Col span={4}>
                                                产品编号
                                            </Col>
                                            <Col span={20}>
                                                {product.productNum}
                                            </Col>
                                        </Row>
                                        <Row style={{background: "#f4f4f4"}}>
                                            <Col span={4}>
                                                CAS号
                                            </Col>
                                            <Col span={20}>
                                                {product.productCas}
                                            </Col>
                                        </Row>
                                        <Row style={{background: "#f4f4f4"}}>
                                            <Col span={4}>
                                                规格
                                            </Col>
                                            <Col span={20}>
                                                {product.productSpec}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={4}>
                                                库存
                                            </Col>
                                            <Col span={20}>
                                                {product.productStock === 0 ?
                                                    "无货" : ">" + product.productStock}

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={4}>
                                                有效期
                                            </Col>
                                            <Col span={20}>
                                                {moment(product.productValidDate).format("YYYY-MM-DD")}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={4} style={{
                                                paddingTop: 20,
                                                paddingBottom: 20
                                            }}>
                                                标准值
                                            </Col>
                                            <Col span={20}>
                                                <Row gutter={[10, 10]}>
                                                    {product.productValidValue.map((item, index) => {
                                                        return <Col span={6} key={index}>
                                                            <div onClick={this.onProductValidClick.bind(this, index)}
                                                                 className={index === cart.productValid ? "testing-standard selected" : "testing-standard"}>
                                                                {item}</div>
                                                        </Col>
                                                    })}
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={4}>
                                                购买数量
                                            </Col>
                                            <Col span={18} style={{paddingTop: 5}}>
                                                <Stepper max={product.productStock} onChange={this.onProductNumChange}/>
                                            </Col>
                                        </Row>
                                        {cartAdding ?
                                            <div className="pro-btn">
                                            <span className="btn-round">
                                                <Icon type="loading"/>
                                            </span>
                                                <span className="btn-round btn-light">
                                                 <Icon type="loading"/>
                                            </span>
                                            </div> :
                                            <div className="pro-btn">
                                                <span className="btn-round" onClick={this.onClickPayment}>
                                                立即购买
                                                 </span>
                                                <span className="btn-round btn-light" onClick={this.onClickPayment}>
                                                加入购物车
                                                </span>
                                            </div>
                                        }
                                    </Col>
                                }
                            </Row>
                        </div>
                    </div>
                </div>
            </Content>
            <PageFooter/>
        </Layout>
    }
}
