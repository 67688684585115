/**
 * Created by Administrator on 2020/4/3 0003.
 */
/**
 * Created by Administrator on 2020/4/3 0003.
 */
/**
 * Created by Administrator on 2020/4/3 0003.
 */
import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {Divider, Icon} from "antd";
import moment from "moment";
import {login} from "../../api/apiService";

class TopBanner extends React.Component {
    constructor(props) {
        super(props);
        const token = localStorage.getItem("token");
        const userName = localStorage.getItem("userName");
        this.state = {
            userName: token && userName ? userName : null
        }
    }

    componentDidMount() {
        const token = localStorage.getItem("token");
        const userName = localStorage.getItem("userName");
        this.setState(
            {
                userName: token && userName ? userName : null
            }
        );
        const expiration = localStorage.getItem("expiration");
        if (!expiration) {
            return;
        }
        const loginDate = moment(expiration).valueOf();
        const now = moment().valueOf();
        if (loginDate < now) {
            localStorage.clear();
            this.props.history.push("/login")
        }
    }

    loginOut = () => {
        localStorage.clear();
        this.props.history.push("/")
    };

    render() {
        return <div className="top-bar">
            <div className="top-bar-wrapper">
                <div className="left">致正科技</div>
                {this.state.userName === null ?
                    <div className="right">
                        您好，
                        <Link to="/login" style={{marginRight: 12}}>
                            请登录
                        </Link>
                        <Divider type="vertical"/>
                        <Link to="/login" style={{marginLeft: 12, marginRight: 12}}>
                            我的订单
                        </Link>
                        <Divider type="vertical"/>
                        <Link to="/login" style={{marginLeft: 12, marginRight: 12}}>
                            <Icon type="shopping-cart" style={{marginRight: 6}}/>购物车
                        </Link>
                        <Divider type="vertical"/>
                        <Link to="/login" style={{marginLeft: 12, marginRight: 12}}>
                            个人中心
                        </Link>
                        <Divider type="vertical"/>
                    </div>
                    :
                    <div className="right">
                        <span style={{marginRight: 12}}>你好,会员</span>
                        <span style={{marginRight: 12}}>{this.state.userName}</span>
                        <Divider type="vertical"/>
                        <Link to="/center/order" style={{marginLeft: 12, marginRight: 12}}>
                            我的订单
                        </Link>
                        <Divider type="vertical"/>
                        <Link to="/payment" style={{marginLeft: 12, marginRight: 12}}>
                            <Icon type="shopping-cart" style={{marginRight: 6}}/>购物车
                        </Link>
                        <Divider type="vertical"/>
                        <Link to="/center/account" style={{marginLeft: 12, marginRight: 12}}>
                            个人中心
                        </Link>
                        <Divider type="vertical"/>
                        <span style={{marginLeft: 12, cursor: "pointer"}} onClick={this.loginOut}>
                            退出登录
                        </span>
                    </div>
                }

            </div>
        </div>
    }
}

export default withRouter(TopBanner);