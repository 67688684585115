import {request} from './config';
import * as Api from '../api/url';

export const login = (data) => {
    return request(Api.USER.login, data);
};

export const register = (data) => {
    return request(Api.USER.register, data);
};
export const getAccount = (data) => {
    return request(Api.USER.get, data);
};
export const updateAccount = (data) => {
    return request(Api.USER.update, data);
};
export const updatePassword = (data) => {
    return request(Api.USER.updatePassword, data);
};
export const forgetPassword = (data) => {
    return request(Api.USER.forgetPassword, data);
};
export const addCart = (data) => {
    return request(Api.USER.addCart, data);
};
export const getCart = (data) => {
    return request(Api.USER.getCart, data);
};
export const removeCart = (data) => {
    return request(Api.USER.removeCart, data);
};
export const addAddress = (data) => {
    return request(Api.ADDRESS.add, data);
};

export const getAddresses = (data) => {
    return request(Api.ADDRESS.get, data);
};

export const removeAddresses = (data) => {
    return request(Api.ADDRESS.remove, data);
};


export const getProduct = (data) => {
    return request(Api.PRODUCT.getProduct, data);
};

export const getProducts = (data) => {
    return request(Api.PRODUCT.getProducts, data);
};
export const searchProducts = (data) => {
    return request(Api.PRODUCT.searchProducts, data);
};

export const submitOrder = (data) => {
    return request(Api.ORDER.submit, data);
};

export const getOrders = (data) => {
    return request(Api.ORDER.get, data);
};
export const getNews = (data) => {
    return request(Api.NEWS.get, data);
};

export const registerVerificationCode = (data) => {
    return request(Api.MAIL.registerVerificationCode, data);
};
export const checkVerificationCode = (data) => {
    return request(Api.MAIL.checkVerificationCode, data);
};
export const accountVerificationCode = (data) => {
    return request(Api.MAIL.accountVerificationCode, data);
};
export const forgetVerificationCode = (data) => {
    return request(Api.MAIL.forgetVerificationCode, data);
};