/**
 * Created by Administrator on 2020/4/16 0016.
 */
/**
 * Created by Administrator on 2020/4/3 0003.
 */
import React from 'react';
import {Layout} from "antd";
const {Footer} = Layout;
export default class PageFooter extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Footer className="footer">
            <div className="footer-content">
                <div className="footer-line"/>
                <div className="footer-contact">
                    <p>
                        <span >电话: 18280000124 17628662300 15208133106</span><span className="fax">传真: 182 8000 0124</span><span >联系人: 汤经理</span>
                    </p>
                </div>
                <div className="footer-address">
                    <p><span>地址: 四川省成都市郫都区百草路898号</span></p>
                </div>
                <div className="footer-copyright">四川中测致正科技有限公司 ©2021 版权所有 蜀ICP备20005924号-1</div>
            </div>
        </Footer>
    }
}
