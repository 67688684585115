/**
 * Created by Administrator on 2020/4/8 0008.
 */
/**
 * Created by Administrator on 2020/4/8 0008.
 */
import React from 'react';
import {
    Select,
    Tabs, Row, Col, Layout
} from 'antd';
import "./../css/center.css"
import TopBanner from "../component/layout/TopBanner";
import SearchBar from "../component/layout/SearchBar";
import NavigationBar from "../component/layout/NavigationBar";
import CenterMenu from "../component/center/CenterMenu";
import OrderListComponent from "../component/center/OrderListComponent";
import PageFooter from "../component/layout/PageFooter";
const {TabPane} = Tabs;
const {Option} = Select;
const {Header, Content, Footer} = Layout;
export  default class UserOrderPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }


    render() {
        return <Layout className="layout center">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                <NavigationBar selectedKey="home"/>
            </Header>
            <Content overlay="" className="body">
                <div className="center-container">
                    <div className="center-wrapper">
                        <Row gutter={24}>
                            <Col span={5}>
                                <CenterMenu selectMenu="order"/>
                            </Col>
                            <Col span={19}>
                                <div className="center-body">
                                    <Tabs defaultActiveKey="1" animated={false}
                                          style={{minHeight: "calc(100vh - 223px - 150px - 80px)"}}>
                                        <TabPane tab="全部订单" key="1">
                                            <OrderListComponent />
                                        </TabPane>
                                        <TabPane tab="发货中" key="2">
                                            <OrderListComponent status="processing"/>
                                        </TabPane>
                                        <TabPane tab="已完成" key="3">
                                            <OrderListComponent status="complete"/>
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

            </Content>
            <PageFooter/>
        </Layout>
    }
}
