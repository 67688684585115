/**
 * Created by Administrator on 2020/4/3 0003.
 */
/**
 * Created by Administrator on 2020/4/3 0003.
 */
import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {Dropdown, Icon, Menu} from "antd";
const menu = (
    <Menu style={{height: 380}}>
        <Menu.Item
            style={{padding: "0px 20px", lineHeight: "40px", color: "#555555", fontSize: "16px", fontWeight: 500}}>
            <Link to={"/search/all/anything"}>
                全部
            </Link>
        </Menu.Item>
        <Menu.Item
            style={{padding: "0px 20px", lineHeight: "40px", color: "#555555", fontSize: "16px", fontWeight: 500}}>
            <Link to={"/search/environmental/anything"}>
                标准液体
            </Link>
        </Menu.Item>
        <Menu.Item
            style={{padding: "0px 20px", lineHeight: "40px", color: "#555555", fontSize: "16px", fontWeight: 500}}>
            <Link to={"/search/food/anything"}>
                标准气体
            </Link>
        </Menu.Item>
        <Menu.Item
            style={{padding: "0px 20px", lineHeight: "40px", color: "#555555", fontSize: "16px", fontWeight: 500}}>
            <Link to={"/search/other/anything"}>
                其他
            </Link>
        </Menu.Item>
    </Menu>
);

class NavigationBar extends React.Component {
    constructor(props) {
        super(props)
    }


    render() {
        return <div className="navigation-container">
            <div className="navigation-wrapper">
                <Dropdown className="categories" placement="bottomLeft" overlay={menu}>
                    <div >
                        <Icon type="menu" style={{fontSize: "20px", marginRight: 10, marginTop: 2}}/>
                        产品分类
                    </div>
                </Dropdown>
                <Menu
                    className="navigation"
                    theme="dark"
                    mode="horizontal"
                    selectedKeys={[this.props.selectedKey]}
                >
                    <Menu.Item key="home">
                        <Link to="/">
                            首页
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="shop">
                        <Link to="/shop">
                            产品商城
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="news">
                        <Link to="/news">
                            企业新闻
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="about">
                        <Link to="/about">
                            关于我们
                        </Link>
                    </Menu.Item>
                </Menu>
                <div className="hot-line">
                    <Icon type="phone" theme="filled" style={{fontSize: 18, marginRight: 10}}/>
                    <span><span style={{fontWeight: "700", marginRight: 5}}>客服热线 :</span>400-028-1662</span>
                </div>
            </div>
        </div>
    }
}
export default withRouter(NavigationBar);
