/**
 * Created by Administrator on 2020/4/13 0013.
 */
/**
 * Created by Administrator on 2020/3/26 0026.
 */
import React from 'react';
import '../css/about.css';
import {BrowserRouter as Router, Route, Link} from "react-router-dom"
import {Col, Layout, Meta, Row} from "antd";
// import BackgroundAboutUs from "./../images/background_about_us.jpg"
// import BackgroundAboutUs1 from "./../images/background_about_us1.jpg"
import SearchBar from "../component/layout/SearchBar";
import NavigationBar from "../component/layout/NavigationBar";
import TopBanner from "../component/layout/TopBanner";
// import * as ApiService from "./../api/apiService";
import PageFooter from "../component/layout/PageFooter";
import BackgroundShouQuanShu from "../images/background_shouquanshu.png"
import BackgroundBusinessLicense from "../images/background_yingyezhizhao_shu.png"

const {Header, Content, Footer} = Layout;
export default class AboutUsPage extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return <Layout className="layout about">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                <NavigationBar selectedKey="about"/>
            </Header>
            <Content overlay="" className="body">
                <div className="about-us-container">
                    <div className="about-us-wrapper">
                        <div className="about-us-body">
                            <Row gutter={[20, 20]}>
                                <Col span={24}>
                                    <div className="about-us-text-container">
                                        <div className="about-us-text-tag">ABOUT US</div>
                                        <h2 className="about-us-text-title">
                                            关于我们
                                        </h2>
                                        <div className="about-us-text-content">
                                            四川致正科技有限公司主要从事标准物质
                                            (气体标准物质、溶液标准物质和固体标准物
                                            质）、进口标准品、金属固体标物和溶液标物
                                            的生产和销售工作，并为用户提供专业化技术
                                            咨询和服务。本公司是四川中测标物科技有限
                                            公司的专属销售单位，产品质量可靠，技术服
                                            务专业、优质。
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <img alt="" style={{width: "100%"}} src={BackgroundShouQuanShu}/>
                                </Col>
                                <Col span={12}>
                                    <img  alt="" style={{width: "100%"}} src={BackgroundBusinessLicense}/>
                                </Col>
                                {/*<Col span={12} className="about-us-image-container">*/}
                                {/*    <div className="about-us-image-wrapper">*/}
                                {/*        <div className="center"/>*/}
                                {/*        /!*<div className="top">*!/*/}
                                {/*            /!*<img src={BackgroundAboutUs1}/>*!/*/}
                                {/*        /!*</div>*!/*/}
                                {/*        /!*<div className="left">*!/*/}
                                {/*            /!*<img src={BackgroundAboutUs}/>*!/*/}
                                {/*        /!*</div>*!/*/}
                                {/*    </div>*/}

                                {/*</Col>*/}
                            </Row>
                        </div>
                    </div>
                </div>
            </Content>
            <PageFooter/>
        </Layout>
    }
}
