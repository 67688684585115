/**
 * Created by Administrator on 2020/4/10 0010.
 */
/**
 * Created by Administrator on 2020/3/30 0030.
 */
import React from 'react';
import {Icon, Row, Col, Skeleton} from "antd";
import {Link} from "react-router-dom";
import * as ApiService from "./../../api/apiService";

export default class ShopWindowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            loading: true,
        }
    }

    componentDidMount() {
        const params = {
            productType: this.props.productType,
            page: 0,
            pageSize: 9
        };
        ApiService.getProducts(params).then((result) => {
            this.setState({
                loading: false,
                products: result.list
            });
        }).catch((error) => {
        })
    }

    render() {
        const {products, loading} = this.state;
        let shopWindows;
        if (loading) {
            //设置要显示加载效果的橱窗个数
            const list = [];
            for (let i = 0; i < 9; i++) {
                list.push(i)
            }
            shopWindows = list.map((item, index) => {
                return <Col span={4} key={index}>
                    <div className="shop-window-item">
                        <Skeleton active/>
                    </div>
                </Col>
            })
        } else {
            shopWindows = products.map((item, index) => {
                const urls = JSON.parse(item.productUrl);
                return <Col span={4} key={index}>
                    <Link to={{pathname: "/product/" + item.productId, state: {productTypeName: item.productTypeName}}}>
                        <div className="shop-window-item">
                            <img className="img-responsive" src={urls[0]} alt=""/>
                            <span className="tag">{item.productTypeName}</span>
                            <p className="title">{item.productName}
                            </p>
                            <p className="rev">
                                <span>{item.productIntroduction}</span>
                            </p>
                            <div className="price">￥ {(item.productPrice).toFixed(2)}</div>
                            <span className="cart-btn">
                                            <Icon type="shopping-cart"/>
                                        </span>
                        </div>
                    </Link>
                </Col>
            })
        }
        return <div className="shop-window-container">
            <div className="shop-window-wrapper">
                <div className="shop-window-header">
                    <h2>{this.props.title}</h2>
                    <hr/>
                </div>
                <div className="shop-window-body">
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <div
                                className={this.props.title === "标准液体" ? "type-first-preview" : "type-second-preview"}/>
                        </Col>
                        {shopWindows}
                    </Row>
                </div>
            </div>

        </div>
    }
}
