import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import Loadable from "react-loadable"
// import HomePage from "./pages/HomePage";
import ShoppingMallPage from "./pages/ShoppingMallPage";
import ProductDetailPage from "./pages/ProductDetailPage";
import PaymentPage from "./pages/PaymentPage";
import LoginPage from "./pages/LoginPage";
import SearchPage from "./pages/SearchPage";
import RegisterPage from "./pages/RegisterPage";
import UserAddressPage from "./pages/UserAddressPage";
import UserOrderPage from "./pages/UserOrderPage";
import UserAccountPage from "./pages/UserAccountPage";
import AboutUsPage from "./pages/AboutUsPage";
import PaymentSuccessfulPage from "./pages/PaymentSuccessfulPage";
import NewsPage from "./pages/NewsPage";
import NewsInfoPage from "./pages/NewsInfoPage";
import UserPasswordPage from "./pages/UserPasswordPage";
import ForgetPage from "./pages/ForgetPage";


const HomePage = Loadable({
    loader: () => import( "./pages/HomePage"),
    loading: () => {
        return <div className="loading-container">
            <div className="loading"/>
        </div>
    },
})
export default class App extends React.Component {
    render() {
        return (
            <Router>
                <Route exact path="/" component={HomePage}/>
                <Route exact path="/about" component={AboutUsPage}/>
                <Route exact path="/login" component={LoginPage}/>
                <Route exact path="/register" component={RegisterPage}/>
                <Route exact path="/forget" component={ForgetPage}/>
                <Route exact path="/shop" component={ShoppingMallPage}/>
                <Route exact path="/news" component={NewsPage}/>
                <Route exact path="/product/:productId" component={ProductDetailPage}/>
                <Route exact path="/payment" component={PaymentPage}/>
                <Route exact path="/payment_successful" component={PaymentSuccessfulPage}/>
                <Route exact path="/search/:type/:keyword" component={SearchPage}/>
                <Route exact path="/center/address" component={UserAddressPage}/>
                <Route exact path="/center/order" component={UserOrderPage}/>
                <Route exact path="/center/account" component={UserAccountPage}/>
                <Route exact path="/center/password" component={UserPasswordPage}/>
                <Route exact path="/news_content" component={NewsInfoPage}/>
            </Router>
        )
    }

}


