/**
 * Created by Administrator on 2020/4/2 0002.
 */
import React from 'react';
import {Icon} from "antd";
export default class Stepper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            num: 1
        }
    }

    onClickSub = () => {
        const {num} = this.state;
        if (num <= 1)
            return;
        this.setState({
            num: num - 1
        });
        if (this.props.onChange) {
            this.props.onChange(num - 1)
        }
    };

    onClickAdd = () => {
        const {num} = this.state;
        if (num >= this.props.max)
            return;
        this.setState({
            num: num + 1
        });
        if (this.props.onChange) {
            this.props.onChange(num + 1)
        }
    };

    render() {
        const {num} = this.state;
        return <div style={{display: "inline-block"}}>
            <div style={{
                height: 40,
                width: 40,
                display: "inline-block",
                border: "1px solid #E5E5E5",
                lineHeight: "40px",
                textAlign: "center",
                cursor: "pointer"
            }}
                 onClick={this.onClickSub}
            ><Icon type="minus"/></div>
            <div style={{
                height: 40,
                width: 60,
                display: "inline-block",
                border: "1px solid #E5E5E5",
                lineHeight: "40px",
                textAlign: "center",
                borderLeft: "none",
                borderRight: "none"
            }}>
                {num}
            </div>
            <div style={{
                height: 40,
                width: 40,
                display: "inline-block",
                border: "1px solid #E5E5E5",
                lineHeight: "40px",
                textAlign: "center",
                cursor: "pointer"
            }} onClick={this.onClickAdd}><Icon type="plus"/></div>
        </div>
    }
}