/**
 * Created by Administrator on 2020/4/16 0016.
 */
import React from 'react';
import "./../css/news.css"
import {Col, Divider, Icon, Layout, Pagination, Row, Tabs} from "antd";
import TopBanner from "../component/layout/TopBanner";
import SearchBar from "../component/layout/SearchBar";
import NavigationBar from "../component/layout/NavigationBar";
import * as ApiService from "./../api/apiService";
import PageFooter from "../component/layout/PageFooter";
import moment from "moment";
const {Header, Content, Footer} = Layout;
export default class NewsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newsTypes: [{
                title: "公司新闻"
            }, {
                title: "行业动态"
            }, {
                title: "通知公告"
            }],
            selectNewsType: 0,
            news: [],
            page: 1,
            totalPage: 1,
            pageSize: 5
        }
    }

    componentDidMount = () => {
        this.getNews(0)
    };

    onPageChange = (page) => {
        this.getNews(page)
    };

    onClickNewsType = (index, event) => {
        this.setState({
            selectNewsType: index
        }, () => {
            this.getNews(0)
        });
    };

    getNews = (page) => {
        const {pageSize, selectNewsType, newsTypes} = this.state;
        console.log(selectNewsType);
        const newsType = newsTypes[selectNewsType].title;
        const params = {
            newsType: newsType,
            page: page,
            pageSize: pageSize,
        };
        ApiService.getNews(params).then((result) => {
            const list = result.list;
            const totalPage = result.pages;
            const page = result.pageNum;
            this.setState({
                loading: false,
                totalPage: totalPage,
                page: page,
                news: list
            });
        }).catch((error) => {
        })
    };

    render() {
        const {newsTypes, selectNewsType, news, totalPage, page} = this.state;
        return <Layout className="layout news">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                <NavigationBar selectedKey="news"/>
            </Header>
            <Content overlay="" className="body">
                <div className="news-container-top"/>
                <div className="news-container">
                    <div className="news-wrapper">
                        <Row gutter={30}>
                            <Col span={6} className="news-type-menu-wrapper">
                                <div className="news-type-menu">
                                    {newsTypes.map((item, index) => {
                                        return <div key={index}
                                                    onClick={this.onClickNewsType.bind(this, index)}
                                                    className={index === selectNewsType ? "news-type-menu-item selected" : "news-type-menu-item"}>
                                            {item.title}
                                        </div>
                                    })}
                                </div>
                                <div style={{
                                    background: "#fff",
                                    marginTop: 20,
                                    padding: 25,
                                    borderRadius: 6,
                                    boxShadow: "0px 2px 8px #888888"
                                }}>
                                    {/*<h1 style={{fontSize:18}}>致正科技有限公司</h1>*/}
                                    <div style={{marginBottom: 6}}>
                                        <Icon type="phone"/><span style={{marginLeft: 12}}>联系电话：400-028-1662</span>
                                    </div>
                                    <div style={{marginBottom: 6}}>
                                        <Icon type="mail"/><span
                                        style={{marginLeft: 12}}>E-mail：274490464@qq.com</span>
                                    </div>
                                    <div>
                                        <Icon type="desktop"/><span style={{marginLeft: 12}}>网 址：www.zhizhengkj.com</span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={18}>
                                <div className="news-list-container">
                                    {/*<img src={BackgroundNewsHeader} style={{width: "100%"}}/>*/}
                                    {news.map((item, index) => {
                                        const day = moment(item.newsDate).format("DD");
                                        const date = moment(item.newsDate).format("YYYY.MM");
                                        return <div key={index} className="news-list-item" onClick={() => {
                                            this.props.history.push({pathname: "/news_content", state: {news: item}})
                                        }
                                        }>
                                            <div className="news-list-item-date">
                                                <p className="news-list-item-day">{day}</p>
                                                <p>{date}</p>
                                            </div>
                                            <div className="news-list-item-body">
                                                <p className="news-list-item-title">{item.newsTitle}</p>
                                                <p>
                                                    {item.newsHeadlines}
                                                </p>
                                            </div>
                                        </div>
                                    })}
                                </div>
                                <div style={{textAlign: "center", marginTop: 40}}>
                                    <Pagination current={page} total={totalPage} defaultPageSize={1}
                                                onChange={this.onPageChange}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
            <PageFooter/>
        </Layout>
    }
}