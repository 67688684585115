/**
 * Created by Administrator on 2020/4/13 0013.
 */
/**
 * Created by Administrator on 2020/4/8 0008.
 */
/**
 * Created by Administrator on 2020/4/8 0008.
 */
import React from 'react';
import {
    Card, Row, Col, Table, Pagination, Skeleton, BackTop, Modal
} from 'antd';
import * as ApiService from "../../api/apiService";
import moment from "moment";

export default class OrderListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: 5,
            totalPage: 1,
            orders: [],
            orderGetting: false,
        }
    }

    componentDidMount() {
        this.getOrders(0)
    }

    onPageChange = (page) => {
        this.getOrders(page)
    };

    getOrders = (page) => {
        this.setState({
            orderGetting: true
        });
        ApiService.getOrders({page: page, pageSize: this.state.pageSize, status: this.props.status}).then((result) => {
            const list = result.list;
            const totalPage = result.pages;
            const page = result.pageNum;
            this.setState({
                orders: list,
                totalPage: totalPage,
                page: page,
                orderGetting: false
            })
        }).catch((error) => {
            console.log(error)
        })
    };

    render() {
        const {orders, totalPage, page, orderGetting} = this.state;
        const columns = [
            {
                title: '产品',
                dataIndex: 'orderProductName',
                key: 'orderProductName',
                width: "50%",
                render: (val, record) => {
                    return <div>
                        <img src={record.orderProductUrl} style={{width: 74, height: 74, marginRight: 20}}/>
                        <span>{val}</span>
                    </div>
                }
            },
            {
                title: '价格',
                dataIndex: 'orderProductPrice',
                key: 'orderProductPrice',
                width: "12.5%",
                render: val => {
                    return <span>{(val).toFixed(2)}元</span>
                }
            },
            {
                title: '数量',
                dataIndex: 'orderProductCount',
                key: 'orderProductCount',
                width: "12.5%",
            }, {
                title: '总价',
                dataIndex: 'productTotalPrice',
                key: 'productTotalPrice',
                width: "12.5%",
                render: (val, record) => {
                    return <span>{(record.orderProductCount * record.orderProductPrice).toFixed(2)}元</span>
                }
            }, {
                title: '',
                dataIndex: 'orderLogisticsNum',
                key: 'orderLogisticsNum',
                render: (val, record) => {
                    return <div>
                        <div onClick={() => {
                            Modal.success({
                                content: <div>
                                    <Row style={{borderBottom: "1px solid #F7F7F7", paddingBottom: 12}}>
                                        <Col span={12}>单号: {val}</Col>
                                        <Col
                                            span={12}>{moment(record.orderLogisticsTime).format("YYYY年MM月DD日 HH:mm:ss")}</Col>
                                    </Row>
                                </div>,
                            });
                        }} style={{color: "rgba(3,139,180,0.99)",cursor:"pointer"}}>[物流信息]
                        </div>
                        {/*<div style={{color:"rgba(3,139,180,0.99)"}}>日期 {record.orderLogisticsTime}</div>*/}
                    </div>
                }
            }
        ];
        return <div>
            <div
                style={{padding: "12px 24px", background: "#f5f5f5", marginBottom: 20}}>
                <Row>
                    <Col span={12}>
                        <div>订单详情</div>
                    </Col>
                    <Col span={3}>
                        <div style={{textAlign: "center"}}>单价</div>
                    </Col>
                    <Col span={3}>
                        <div style={{textAlign: "center"}}>数量</div>
                    </Col>
                    <Col span={3}>
                        <div style={{textAlign: "center"}}>金额</div>
                    </Col>
                </Row>
            </div>
            <Skeleton active={true} loading={orderGetting}>
                {orders.map((item, index) => {
                    let totalPrice = 0;
                    const dataSource = item.orderProducts;
                    const status = item.orderStatus;
                    let statusElement;
                    if (status === "processing") {
                        statusElement = <span
                            style={{color: "#119d5f"}}>准备发货</span>
                    } else {
                        statusElement = <span
                            style={{color: "#ff4430"}}>已经完成</span>
                    }
                    dataSource.forEach((item) => {
                        totalPrice += (item.orderProductPrice * item.orderProductCount)
                    });
                    return <Card key={item.orderId}
                                 bodyStyle={{padding: 0, marginBottom: 40}}
                                 extra={<span>订单状态 :{statusElement}</span>}
                                 title={<span style={{color: "#aaa", fontSize: 14}}><span
                                     style={{marginRight: 30}}>{moment(item.orderTime).format("YYYY年MM月DD日 HH:mm:ss")}</span>订单号: <span
                                     style={{color: "#222"}}>{item.orderId}</span></span>}>
                        <div style={{
                            padding: "12px 24px",
                            background: "#f5f5f5",
                            borderTop: "1px solid #E7E7E7"
                        }}>
                            <Row>
                                <Col span={6}>
                                    <div>收货人: <span style={{color: "#222"}}>{item.orderContactName}</span></div>
                                </Col>
                                <Col span={6}>
                                    <div style={{textAlign: "center"}}>订单金额:
                                        <span style={{color: "#ff5e33"}}> {(totalPrice).toFixed(2)} 元</span>
                                    </div>
                                </Col>
                                {/*<Col span={8}>*/}
                                {/*    <div style={{textAlign: "center"}}>物流信息 : <span*/}
                                {/*        style={{*/}
                                {/*            color: "#1890ff",*/}
                                {/*            cursor: "pointer"*/}
                                {/*        }}>[查看物流]</span></div>*/}
                                {/*</Col>*/}
                            </Row>
                        </div>
                        <Table rowKey="orderProductId" showHeader={false} dataSource={dataSource} columns={columns}
                               bordered={true}
                               pagination={false}/>
                    </Card>
                })}
            </Skeleton>
            <div style={{textAlign: "right", marginTop: 20}}>
                <Pagination current={page} total={totalPage} defaultPageSize={1} onChange={this.onPageChange}/>
            </div>
            <BackTop/>
        </div>
    }
}
