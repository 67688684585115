/**
 * Created by Administrator on 2020/4/3 0003.
 */
import React from 'react';
import IconLogo from "../../images/logo.png";
import {withRouter} from "react-router-dom";
import {Icon, Input, Select} from "antd";
const {Option} = Select;
class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productType: "all",
            keyword: "anything"
        }
    }

    onClickSearch = () => {
        this.props.history.push('/search/' + this.state.productType + "/" + this.state.keyword);
    };

    onProductTypeChange = (value) => {
        this.setState({
            productType: value
        })
    };

    render() {
        return <div className="search-container">
            <img className="logo" src={IconLogo}/>
            <div className="search-bar">
                <Select className="filter-option" defaultValue="all" onChange={this.onProductTypeChange}>
                    <Option value="all">全部</Option>
                    <Option value="environmental">标准液体</Option>
                    <Option value="food">标准气体</Option>
                    <Option value="other">其他</Option>
                </Select>
                <div className="divider vertical"/>
                <Input className="search-input" onChange={(event) => {
                    this.setState({
                        keyword: event.target.value
                    })
                }
                }/>
                <div className="submit" onClick={this.onClickSearch}>
                    <Icon type="search"/>
                </div>
            </div>
        </div>
    }
}
export default withRouter(SearchBar);
