export const USER = {
    login: {
        url: 'user/login',
        method: "post"
    },
    register: {
        url: 'user/register',
        method: "post"
    },
    get: {
        url: 'user/get_account',
        method: "get"
    },
    update: {
        url: 'user/update_account',
        method: "post"
    },
    updatePassword: {
        url: 'user/update_password',
        method: "post"
    },
    forgetPassword: {
        url: 'user/forget_password',
        method: "post"
    },
    addCart: {
        url: 'user/add_cart',
        method: "post"
    },
    getCart: {
        url: 'user/get_cart',
        method: "post"
    },
    removeCart: {
        url: 'user/remove_cart',
        method: "post"
    }
};

export const ADDRESS = {
    add: {
        url: 'address/add',
        method: "post"
    },
    get: {
        url: 'address/get_addresses',
        method: "get"
    },
    remove: {
        url: 'address/remove',
        method: "post"
    }
}

export const PRODUCT = {
    getProduct: {
        url: 'product/get_product',
        method: "get"
    },
    getProducts: {
        url: 'product/get_products',
        method: "get"
    },
    searchProducts: {
        url: 'product/search_products',
        method: "get"
    }
};

export const ORDER = {
    submit: {
        url: 'order/submit',
        method: "post"
    },
    get: {
        url: 'order/get_orders',
        method: "get"
    }
};

export const NEWS = {
    get: {
        url: 'news/get_news',
        method: "get"
    },
};

export const MAIL = {
    registerVerificationCode: {
        url: 'mail/register_verification_code',
        method: "post"
    },
    accountVerificationCode: {
        url: 'mail/account_verification_code',
        method: "post"
    },
    forgetVerificationCode:{
        url: 'mail/forget_verification_code',
        method: "post"
    },
    checkVerificationCode: {
        url: 'mail/check_verification_code',
        method: "post"
    },
}