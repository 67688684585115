/**
 * Created by Administrator on 2020/4/2 0002.
 */
/**
 * Created by Administrator on 2020/3/31 0031.
 */
/**
 * Created by Administrator on 2020/3/30 0030.
 */
import React from 'react';
import "./../css/register.css"
import {
    Layout,
    Steps, Tabs,
    message
} from "antd";
import SearchBar from "../component/layout/SearchBar";
import NavigationBar from "../component/layout/NavigationBar";
import TopBanner from "../component/layout/TopBanner";
import RegisterStep1Component from "../component/register/RegisterStep1Component";
import RegisterStep2Component from "../component/register/RegisterStep2Component";
import RegisterStep3Component from "../component/register/RegisterStep3Component";
import * as ApiService from "./../api/apiService"
import PageFooter from "../component/layout/PageFooter";
import ForgetStep3Component from "../component/forget/ForgetStep3Component";
import ForgetStep1Component from "../component/forget/ForgetStep1Component";
import ForgetStep2Component from "../component/forget/ForgetStep2Component";

const {Header, Content, Footer} = Layout;
const {Step} = Steps;
const {TabPane} = Tabs;
export default class ForgetPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            phone: ""
        }
    }

    componentDidMount() {
    }

    onNext = (phone) => {
        const {currentStep} = this.state;
        this.setState({
            currentStep: currentStep + 1,
            phone: phone
        })
    };

    // onSubmit = (values) => {
    //     values.phone = this.state.phone;
    //     ApiService.forgetPassword(values).then((result) => {
    //         const {currentStep} = this.state;
    //         this.setState({
    //             currentStep: currentStep + 1,
    //         })
    //     }).catch((error) => {
    //         console.log(error);
    //         const errorDescription = error.message;
    //         message.error(errorDescription);
    //     })
    // };

    render() {
        const {currentStep} = this.state;
        return <Layout className="layout register">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                <NavigationBar/>
            </Header>
            <Content overlay="" className="body">
                <div className="form-container">
                    <div className="form-wrapper">
                        <Steps current={currentStep}>
                            <Step title="验证邮箱"/>
                            <Step title="重设密码"/>
                            <Step title="修改成功"/>
                        </Steps>
                        <Tabs activeKey={String(currentStep)} animated={false} renderTabBar={() => {
                            return <div style={{height: 0}}/>
                        }
                        }>
                            <TabPane tab="" key="0">
                                <ForgetStep1Component onNext={this.onNext}/>
                            </TabPane>
                            <TabPane tab="" key="1">
                                <ForgetStep2Component onNext={this.onNext} phone={this.state.phone}/>
                            </TabPane>
                            <TabPane tab="" key="2">
                                <ForgetStep3Component/>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </Content>
            <PageFooter/>
        </Layout>
    }
}