/**
 * Created by Administrator on 2020/4/2 0002.
 */
/**
 * Created by Administrator on 2020/3/31 0031.
 */
/**
 * Created by Administrator on 2020/3/30 0030.
 */
import React from 'react';
import "./../css/login.css"
import {
    Input, Layout, Button, Icon, Row, Col, Form,
    Checkbox, message
} from "antd";
import {Link} from "react-router-dom";
import BackgroundLeft from "./../images/login_left.png";
import BackgroundRightTop from "./../images/background_login_right_top.png";
import BackgroundRightBottom from "./../images/background_login_right_bottom.png";
import SearchBar from "../component/layout/SearchBar";
import NavigationBar from "../component/layout/NavigationBar";
import TopBanner from "../component/layout/TopBanner";
import * as ApiService from "./../api/apiService"
import PageFooter from "../component/layout/PageFooter";
import moment from "moment";

const {Header, Content, Footer} = Layout;

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    login = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err)
                return;
            this.setState({
                loading: true,
            });
            ApiService.login(values).then((result) => {
                this.setState({
                    loading: false,
                });
                localStorage.setItem("token", result.token);
                localStorage.setItem("userName", result.userNickName);
                localStorage.setItem("expiration", moment(Date()).add(14, 'days').format("YYYY-MM-DD HH:mm:ss"))
                if (result.userUrl)
                    localStorage.setItem("userUrl", result.userUrl);
                else {
                    localStorage.setItem("userUrl", "");
                }
                this.props.history.push('/shop');
            }).catch((error) => {
                this.setState({
                    loading: false
                });
                const errorDescription = error.message;
                if (errorDescription === "Request failed with status code 403") {
                    message.error("登录失败,帐号密码错误");
                } else {
                    message.error("登录失败,网络错误");
                }

            })
        });
    };

    render() {
        const {loading} = this.state;
        const {getFieldDecorator} = this.props.form;
        return <Layout className="layout login">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                <NavigationBar/>
            </Header>
            <Content overlay="" className="body">
                <div className="login-container">
                    <Row>
                        <Col span={8} offset={2}>
                            <img src={BackgroundLeft}/>
                        </Col>
                        <Col span={12}>
                            <Row className="login-wrapper">
                                <img className="background top" src={BackgroundRightTop}/>
                                <img className="background bottom" src={BackgroundRightBottom}/>
                                <Col span={16} offset={4}>
                                    <Form className="login-form" onSubmit={this.login}>
                                        <Form.Item>
                                            {getFieldDecorator('account', {
                                                rules: [{required: false, message: '请输入正确的帐号'}],
                                            })(<Input size="large" placeholder="请输入帐号"
                                                      prefix={<Icon type="home" theme="filled"/>}/>)}

                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator('password', {
                                                rules: [{required: false, message: '请输入正确的密码'}],
                                            })(<Input.Password size="large" placeholder="请输入密码"
                                                               prefix={<Icon type="lock" theme="filled"/>}/>)}

                                        </Form.Item>

                                        <Form.Item name="remember">
                                            <Checkbox>自动登录</Checkbox>
                                            <Link to={"/forget"} className="login-form-forgot">
                                                忘记密码?
                                            </Link>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button loading={loading} className="login-btn" type="primary"
                                                    htmlType="submit">
                                                登录
                                            </Button>
                                            <Link to="/register"
                                                  style={{textAlign: "center", display: "block"}}>立即注册</Link>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Content>
            <PageFooter/>
        </Layout>
    }
}

export default LoginPage = Form.create()(LoginPage);