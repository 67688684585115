/**
 * Created by Administrator on 2020/4/8 0008.
 */
import React from 'react';
import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Select, Icon, Upload, Card, Layout, Skeleton, message
} from 'antd';
import "./../css/center.css"
import ImgCrop from "antd-img-crop";
import * as ApiService from "../api/apiService"
import TopBanner from "../component/layout/TopBanner";
import NavigationBar from "../component/layout/NavigationBar";
import SearchBar from "../component/layout/SearchBar";
import CenterMenu from "../component/center/CenterMenu";
import PageFooter from "../component/layout/PageFooter";

const {Option} = Select;
const {Header, Content, Footer} = Layout;

class UserPasswordPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordUpdating: false,
            verificationGetting: 0,
        }
    }


    componentDidMount() {
    }


    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err)
                return;
            const password = values.password;
            const passwordAgain = values.passwordAgain;
            if (password !== passwordAgain) {
                message.error("两次输入的密码不一致");
                return
            }
            this.setState({
                passwordUpdating: true
            });
            ApiService.updatePassword(values).then((result) => {
                this.setState({
                    passwordUpdating: false,
                });
                message.success("修改成功")
            }).catch((error) => {
                this.setState({
                    passwordUpdating: false,
                });
                message.error(error.message)
            })
        });
    };
    getVerificationCode = () => {
        this.setState({
                verificationGetting: 1
            }
        )
        ApiService.accountVerificationCode().then((result) => {
            this.setState({
                verificationGetting: 2,
            })
        }).catch((error) => {
            console.log(error);
            const errorDescription = error.message;
            message.error(errorDescription);
            this.setState({
                verificationGetting: 1,
            })
        })
    }

    render() {
        const {passwordUpdating, verificationGetting} = this.state;
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 4},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 18},
                sm: {span: 18},
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 8,
                    offset: 4,
                },
            },
        };
        let verificationCodeButton;
        if (verificationGetting === 1) {
            verificationCodeButton = <Button loading={true} type={"primary"} style={{width: "100%"}}>
            </Button>
        } else if (verificationGetting === 2) {
            verificationCodeButton = <Button type={"primary"} style={{width: "100%"}}>已发送
            </Button>
        } else {
            verificationCodeButton = <Button type={"primary"} style={{width: "100%"}}
                                             onClick={this.getVerificationCode}>获取验证码
            </Button>
        }
        return <Layout className="layout center">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                <NavigationBar selectedKey="home"/>
            </Header>
            <Content overlay="" className="body">
                <div className="center-container">
                    <div className="center-wrapper">
                        <Row gutter={24}>
                            <Col span={5}>
                                <CenterMenu selectMenu="password"/>
                            </Col>
                            <Col span={18}>
                                <div className="center-body">
                                    <Card title="修改密码" style={{minHeight: "calc(100vh - 223px - 150px - 80px)"}}>
                                        <Row>
                                            <Col span={16}>
                                                <Form {...formItemLayout} onSubmit={this.onSubmit}>
                                                    <Form.Item label="验证码">
                                                        <Row gutter={8}>
                                                            <Col span={18}>
                                                                {getFieldDecorator('verificationCode', {
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: '请输入验证码',
                                                                        },
                                                                    ],
                                                                })(<Input/>)}
                                                            </Col>
                                                            <Col span={6}>
                                                                {verificationCodeButton}
                                                            </Col>
                                                        </Row>
                                                    </Form.Item>
                                                    <Form.Item label="密码">
                                                        {getFieldDecorator('password', {
                                                            rules: [{required: true, message: '请输入密码'}],
                                                        })(<Input type="password"/>)}
                                                    </Form.Item>
                                                    <Form.Item label="确认密码">
                                                        {getFieldDecorator('passwordAgain', {
                                                            rules: [{required: true, message: '请确认密码'}],
                                                        })(<Input type="password"/>)}
                                                    </Form.Item>
                                                    <Form.Item {...tailFormItemLayout}>
                                                        <Button type="primary" loading={passwordUpdating}
                                                                htmlType={"submit"}>
                                                            修改密码
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
            <PageFooter/>
        </Layout>


    }
}

export default UserPasswordPage = Form.create()(UserPasswordPage);