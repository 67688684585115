/**
 * Created by Administrator on 2020/4/1 0001.
 */
/**
 * Created by Administrator on 2020/3/31 0031.
 */
/**
 * Created by Administrator on 2020/3/30 0030.
 */
import React from 'react';
import "./../css/payment.css"
import {
    Input,
    Layout,
    Icon,
    Row,
    Col,
    Table,
    Form,
    Skeleton,
    Button,
    Spin,
    message,
    Cascader,
    Modal, Result
} from "antd";
import * as ApiService from "../api/apiService"
import SearchBar from "../component/layout/SearchBar";
import NavigationBar from "../component/layout/NavigationBar";
import TopBanner from "../component/layout/TopBanner";
import * as CityUtils from "../utils/CityUtils";
import {Link} from "react-router-dom";
import PageFooter from "../component/layout/PageFooter";

const {Header, Content, Footer} = Layout;
const antdDistrict = [];
const districtData = CityUtils.getCountry();
Object.keys(districtData).forEach((index) => {
    let itemLevel1 = {};
    let itemLevel2 = {};
    itemLevel1.value = districtData[index].name;
    itemLevel1.label = districtData[index].name;
    itemLevel1.children = [];
    let data = districtData[index].cities;
    Object.keys(data).forEach((index) => {
        itemLevel2.value = data[index].name;
        itemLevel2.label = data[index].name;
        itemLevel2.children = [];
        let data2 = data[index].districts;
        let itemLevel3 = {};
        itemLevel3.children = [];
        Object.keys(data2).forEach((index) => {
            itemLevel3.value = data2[index];
            itemLevel3.label = data2[index];
            itemLevel2.children.push(itemLevel3);
            itemLevel3 = {};
        });
        itemLevel1.children.push(itemLevel2);
        itemLevel2 = {};
    });
    antdDistrict.push(itemLevel1)
});

class PaymentPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            addressInserting: false,
            addressGetting: false,
            addressRemoving: false,
            cartGetting: false,
            orderSubmitting: false,
            addresses: [],
            dataSource: [],
            cart: {
                address: {
                    addressId: -1
                },
                remark: ""
            }
        }
    }

    componentDidMount() {
        this.getAddresses();
        this.getCart();
    }

    onClickSubmit = () => {
        this.setState({
            orderSubmitting: true,
        });
        const {cart} = this.state;
        const addressId = cart.address.addressId;
        const remark = cart.remark;
        ApiService.submitOrder({addressId: addressId, remark: remark}).then((result) => {
            this.setState({
                orderSubmitting: false,
            });
            this.props.history.push("/payment_successful")
        }).catch((error) => {
            this.setState({
                orderSubmitting: false,
            });
            message.error(error.message)
        })
    };

    onClickShop = () => {
        this.props.history.push("/")
    };

    onClickAddAddress = () => {
        this.setState({
            visible: true
        })
    };

    onClickSelectAddress = (address, event) => {
        const cart = this.state.cart;
        cart.address = address;
        this.setState({
            address: address
        })
    };

    onClickRemoveCart = (cartProduct, event) => {
        ApiService.removeCart({cartProduct: cartProduct}).then((result) => {
            this.getCart()
        }).catch((error) => {
            console.log(error)
        })
    };

    handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (err)
                return;
            this.setState({
                addressInserting: true,
            });
            ApiService.addAddress(values).then((result) => {
                this.setState({
                    addressInserting: false,
                    visible: false
                });
                this.getAddresses()
            }).catch((error) => {
                this.setState({
                    addressInserting: false
                });
                const errorDescription = error.message;
                message.error(errorDescription);
            })
        })
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        })
    };

    getAddresses = () => {
        this.setState({
            addressGetting: true
        });
        ApiService.getAddresses().then((result) => {
            const cart = this.state.cart;
            if (cart.address.addressId === -1 && result.length > 0) {
                cart.address = result[0]
            }
            this.setState({
                addresses: result,
                addressGetting: false
            })
        }).catch((error) => {
        })
    };

    getCart = () => {
        this.setState({
            cartGetting: true
        });
        ApiService.getCart({}).then((result) => {
            const that = this;
            setTimeout(() => {
                that.setState({
                    cartGetting: false,
                    dataSource: result
                });
            }, 500)
        }).catch((error) => {
            console.log(error)
        })
    };

    render() {
        const {dataSource, addressGetting, addressRemoving, addresses, addressInserting, visible, cartGetting, orderSubmitting} = this.state;
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 4},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 18},
                sm: {span: 18},
            },
        };

        let contentComponent;
        if (addressGetting) {
            contentComponent = <Row gutter={[24, 24]}>
                <Col span={8}>
                    <Skeleton/>
                </Col>
                <Col span={8}>
                    <Skeleton/>
                </Col>
                <Col span={8}>
                    <Skeleton/>
                </Col>
            </Row>
        } else if (!addressGetting && addresses.length === 0) {
            contentComponent = null;
        } else if (!addressGetting && addresses.length > 0) {
            contentComponent =
                addresses.map((item, index) => {
                    const area = item.addressArea.replace("[", "").replace("]", "");
                    return <Col key={index} span={6}>
                        <Spin spinning={addressRemoving}>
                            <div
                                className={this.state.cart.address.addressId === item.addressId ? "logistics-address-item selected" : "logistics-address-item"}
                                onClick={this.onClickSelectAddress.bind(this, item)}>
                                <div className="logistics-address-item-header">
                                    <span>{item.addressContactName}</span>
                                    <span>{item.addressContactPhone}</span>
                                </div>
                                <div className="logistics-address-item-body">
                                    <p>{item.addressCompany}</p>
                                    <p>{area}</p>
                                    <p>{item.addressStreet}</p>
                                </div>
                            </div>
                        </Spin>
                    </Col>
                })
        }
        let cartContentComponent;
        if (cartGetting) {
            const columns = [
                {
                    title: '产品',
                    dataIndex: 'cartProductName',
                    render: (val, record) => {
                        return <Skeleton active paragraph={false}/>
                    }
                },
                {
                    title: '价格',
                    dataIndex: 'cartProductPrice',
                    render: (val, record) => {
                        return <Skeleton active paragraph={false}/>
                    }
                },
                {
                    title: '数量',
                    dataIndex: 'cartProductCount',
                    render: (val, record) => {
                        return <Skeleton active paragraph={false}/>
                    }
                }, {
                    title: '总价',
                    dataIndex: 'productTotalPrice',
                    render: (val, record) => {
                        return <Skeleton active paragraph={false}/>
                    }
                }, {
                    title: "有效值",
                    dataIndex: "cartProductValidValue",
                    render: (val, record) => {
                        return <Skeleton active paragraph={false}/>
                    }
                }, {
                    title: '',
                    dataIndex: 'option',
                    render: (val, record) => {
                        return <Skeleton active paragraph={false}/>
                    }
                },
            ];
            cartContentComponent =
                <Table rowKey="cartProductId" dataSource={[{cartProductId: -1}]} columns={columns} bordered={true}
                       pagination={false}/>

        } else if (!cartGetting && dataSource.length === 0) {
            cartContentComponent = <Result
                icon={<Icon type="smile" theme="twoTone"/>}
                title="还没有采购商品~"
                extra={<Link to={"/shop"}>采购产品</Link>}
            />;
        } else if (!cartGetting && dataSource.length > 0) {
            const columns = [
                {
                    title: '产品',
                    dataIndex: 'cartProductName',
                    render: (val, record) => {
                        return <div>
                            <img src={record.cartProductUrl} style={{width: 74, height: 74, marginRight: 20}}/>
                            <span>{val}</span>
                        </div>
                    }
                },
                {
                    title: '价格',
                    dataIndex: 'cartProductPrice',
                    render: val => {
                        return <span>{(val / 1).toFixed(2)}元</span>
                    }
                },
                {
                    title: '数量',
                    dataIndex: 'cartProductCount',
                }, {
                    title: '总价',
                    dataIndex: 'productTotalPrice',
                    render: (val, record) => {
                        return <span>{(record.cartProductPrice * record.cartProductCount).toFixed(2)}元</span>
                    }
                }, {
                    title: "有效值",
                    dataIndex: "cartProductValidValue"
                },
                {
                    title: '',
                    dataIndex: 'option',
                    render: (val, record) => {
                        return <div style={{textAlign: "center", cursor: "pointer"}}>
                            <Icon type="close" onClick={this.onClickRemoveCart.bind(this, record)}/>
                        </div>
                    }
                },
            ];
            cartContentComponent =
                <Table rowKey="cartProductId" dataSource={dataSource} columns={columns} bordered={true}
                       pagination={false}/>
        }
        return <Layout className="layout payment">
            <Header className="header">
                <TopBanner/>
                <SearchBar/>
                <NavigationBar/>
            </Header>
            <Content overlay="" className="body">
                <Spin size={"large"} spinning={orderSubmitting} tip="正在提交订单">
                    <div className="logistics-container">
                        <div className="logistics-wrapper">
                            <div className="logistics-header">
                                <h2>收货信息</h2>
                                <hr/>
                            </div>
                            <div className="logistics-body">
                                <Row gutter={12}>
                                    {contentComponent}
                                    {!addressGetting &&
                                    <Col span={6}>
                                        <div className="logistics-address-item add" onClick={this.onClickAddAddress}>
                                            <Icon type="plus" style={{fontSize: 36}}/>
                                            <p>使用新地址</p>
                                        </div>
                                    </Col>}
                                </Row>
                            </div>
                        </div>
                    </div>
                    {/*<div className="payment-type-container">*/}
                    {/*<div className="payment-type-wrapper">*/}
                    {/*<div className="payment-type-header">*/}
                    {/*<h2>支付方式</h2>*/}
                    {/*<hr/>*/}
                    {/*</div>*/}
                    {/*<div className="payment-type-body">*/}
                    {/*<Row>*/}
                    {/*<Col span={4}>*/}
                    {/*<div className="payment-type-item">货到付款</div>*/}
                    {/*</Col>*/}
                    {/*</Row>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    <div className="cart-container">
                        <div className="cart-wrapper">
                            <div className="cart-header">
                                <h2>购物车</h2>
                                <hr/>
                            </div>
                            <div className="cart-body">
                                {cartContentComponent}
                            </div>
                        </div>
                    </div>
                    <div className="mark-container">
                        <div className="mark-wrapper">
                            <div className="mark-header">
                                <h2>备注信息</h2>
                                <hr/>
                            </div>
                            <div className="mark-body">
                                <Row>
                                    <Col span={16}>
                                        <Input onChange={(event) => {
                                            const {cart} = this.state;
                                            cart.remark = event.target.value;
                                            this.setState({
                                                cart: cart
                                            })
                                        }} placeholder="填写订单备注"/>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="pro-btn">
                        <div className="btn-round btn-light" onClick={this.onClickShop}>
                            继续采购
                        </div>
                        <div className="btn-round" onClick={this.onClickSubmit}>
                            确认下单
                        </div>
                    </div>
                </Spin>
            </Content>
            <PageFooter/>
            <Modal
                destroyOnClose={true}
                title={"添加收货地址"}
                visible={visible}
                onOk={this.handleOk}
                centered={true}
                width={600}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        取消
                    </Button>,
                    <Button key="submit" type="primary" loading={addressInserting}
                            onClick={this.handleOk}>
                        确认添加
                    </Button>,
                ]}
            >
                <div>
                    <Form {...formItemLayout}>
                        <Form.Item label="收货人">
                            {getFieldDecorator('addressContactName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '收货人',
                                    },
                                ],
                            })(<Input addonBefore={<Icon type="user"/>}
                                      placeholder="收货人"/>)}
                        </Form.Item>
                        <Form.Item label="联系电话">
                            {getFieldDecorator('addressContactPhone', {
                                rules: [
                                    {
                                        required: true,
                                        message: '联系电话',
                                    },
                                    {
                                        pattern: /^1[3|4|5|7|8][0-9]\d{8}$/, message: '请输入正确的联系电话'
                                    }
                                ],
                            })(<Input addonBefore={<Icon type="phone"/>}
                                      placeholder="联系电话"/>)}
                        </Form.Item>
                        <Form.Item label="公司名称">
                            {getFieldDecorator('addressCompany', {
                                rules: [
                                    {
                                        required: true,
                                        message: '联系电话',
                                    },
                                ],
                            })(<Input addonBefore={<Icon type="home"/>}
                                      placeholder="公司名称"/>)}
                        </Form.Item>
                        <Form.Item label="收货地址">
                            {getFieldDecorator('addressArea', {
                                rules: [
                                    {
                                        required: true,
                                        message: '收货地址',
                                    },
                                ],
                            })(<Cascader placeholder="收货地址" options={antdDistrict}/>)}
                        </Form.Item>
                        <Form.Item label="街道地址" style={{marginBottom: 0}}>
                            {getFieldDecorator('addressStreet', {
                                rules: [
                                    {
                                        required: true,
                                        message: '收货地址',
                                    },
                                ],
                            })(<Input
                                placeholder="街道地址"/>)}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </Layout>
    }
}

export default PaymentPage = Form.create()(PaymentPage);